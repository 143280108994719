import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography, SelectChangeEvent, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GridFormField } from "./components/RegisterDeviceFormTemp";
import { Device } from "../../models/DevicesModel";
import { initialDevice, useDevice } from "../../context/DevicesContext";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { useDevicesLocal } from "./context/DevicesLocalContext";

export interface DeviceFormProps {
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeviceForm: React.FC<DeviceFormProps> = ({ setOpenForm }) => {
  const [previewUrl, setPreviewUrl] = useState(""); // 미리보기 URL
  const [formValues, setFormValues] = useState<Device>(initialDevice);
  const { addDevice } = useDevice();
  const [image, setImage] = useState<File>();
  const { handleSelectDevice } = useDevicesLocal();
  const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>([]);
  const isLocationDisabled = formValues.area.includes("본관");

  // 폼 닫기 핸들
  const HandleClose = () => {
    setOpenForm(false);
  };

  // 폼 선택값 변경 핸들
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name: string; value: unknown }> | SelectChangeEvent<string | null>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value === "" ? "" : value,
    }));
  };

  // 폼 전송 핸들
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const success = await addDevice(formValues,image);
    if (success) handleSelectDevice(formValues);
    HandleClose();
  };

  // 이미지 핸들
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: url,
      }));
      setImage(file);
      setPreviewUrl(url);
    }
  };

  // 이미지 닫기 핸들
  const handleCloseImg = () => {
    setFormValues((prevValues) => ({ ...prevValues, imgUrl: null }));
  };

  // 건물 선택에 따라 층 옵션을 업데이트하는 useEffect
  useEffect(() => {
    switch (formValues.area) {
      case "본관 4층":
        setFormValues((prev) => ({ ...prev, location: "메인홀" }));
        setLocationOptions([{ label: "메인홀", value: "메인홀" }]);
        break;
      case "본관 2층":
        setFormValues((prev) => ({ ...prev, location: "메인홀" }));
        setLocationOptions([{ label: "메인홀", value: "메인홀" }]);
        break;
      case "본관 G층":
        setFormValues((prev) => ({ ...prev, location: "복도" }));
        setLocationOptions([{ label: "복도", value: "복도" }]);
        break;
      case "신관 1층":
        setFormValues((prev) => ({ ...prev, location: "" }));
        setLocationOptions([
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
        ]);
        break;
      case "신관 2층":
        setFormValues((prev) => ({ ...prev, location: "" }));
        setLocationOptions([
          { label: "A", value: "A" },
          { label: "M", value: "M" },
          { label: "R", value: "R" },
          { label: "E", value: "E" },
          { label: "D", value: "D" },
          { label: "복도", value: "복도" },
        ]);
        break;
      case "창고":
        setFormValues((prev) => ({ ...prev, location: "" }));
        setLocationOptions([
          { label: "옥상", value: "옥상" },
          { label: "지하", value: "지하" },
        ]);
        break;
      default:
        setLocationOptions([]); // 선택된 건물 옵션이 없을 때 초기화
        break;
    }
  }, [formValues.area]);

  return (
    <Box display={"flex"} height={"100%"}>
      <Grid container sx={{ flex: 1 }} direction={"column"}>
        <Grid item xs={12}>
          <Paper sx={{ height: "100%", boxShadow: 3, padding: 2, display: "flex", flexDirection: "column" }}>
            <Box
              component="form"
              height="100%"
              onSubmit={handleSubmit}
              sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", padding: 2 }}
            >
              {/* 기본 정보 */}
              <Grid container direction={"row"}>
                <Grid container>
                  <Typography variant="h2" paddingY={1}>
                    기본 정보
                  </Typography>
                </Grid>
                {/* 사진 */}
                <Grid item xs={3} flexGrow={1}>
                  <Grid container height={"100%"} alignItems="center" justifyContent="center">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ width: "90%", height: "100%", position: "relative" }}
                    >
                      {formValues.imgUrl !== null ? (
                        formValues.imgUrl && (
                          <>
                            <img
                              src={previewUrl}
                              alt="Uploaded"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                            <Stack sx={{ position: "absolute", top: 0, right: 0 }}>
                              <Button
                                onClick={handleCloseImg}
                                sx={{ minWidth: "20px", minHeight: "20px", padding: "0", color: "grey", backgroundColor: "transparent" }}
                                size="small"
                              >
                                <CloseIcon />
                              </Button>
                            </Stack>
                          </>
                        )
                      ) : (
                        <Box
                          component="label"
                          htmlFor="image-upload"
                          sx={{
                            aspectRatio: "1",
                            height: "80%",
                            borderRadius: "50%",
                            border: "2px dashed #ccc",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            maxWidth: "100%",
                          }}
                        >
                          <input
                            id="image-upload"
                            type="file"
                            accept="image/*"
                            name="imgUrl"
                            hidden
                            onChange={handleImageUpload}
                            style={{ display: "none" }}
                          />
                          <UploadRoundedIcon sx={{ color: "#ccc", fontSize: "30px" }} />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item flex={1} flexGrow={1}>
                  <Grid container gap={1} mb={1}>
                    <GridFormField label="호칭" name="title" required={true} value={formValues.title} onChange={handleChange} />
                    <GridFormField label="장비명" name="deviceName" required={true} value={formValues.deviceName} onChange={handleChange} />
                    <GridFormField label="코드번호" name="codeNumber" value={formValues.codeNumber} onChange={handleChange} />
                  </Grid>

                  <Grid container gap={1} mb={1}>
                    <GridFormField label="제조사" name="deviceCompany" value={formValues.deviceCompany} onChange={handleChange} />
                    <GridFormField label="S/N" name="serialNumber" value={formValues.serialNumber} onChange={handleChange} />
                    <GridFormField
                      label="의료기기 분류"
                      required={true}
                      name="deviceCategory"
                      value={formValues.deviceCategory}
                      onChangeS={handleChange}
                      type="select"
                      options={[
                        { label: "의료기기 1등급", value: "의료기기 1등급" },
                        { label: "의료기기 2등급", value: "의료기기 2등급" },
                        { label: "의료기기 3등급", value: "의료기기 3등급" },
                      ]}
                    />
                  </Grid>
                  <Grid container gap={1}>
                    <GridFormField
                      label="AS회사/연락처"
                      name="asContact"
                      value={formValues.asContact}
                      onChange={handleChange}
                      multiline={true}
                      rows={2}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* 장비 위치 */}
              <Grid container>
                <Typography variant="h2" paddingY={2}>
                  위치 정보
                </Typography>

                <Grid container gap={1}>
                  <GridFormField
                    label="건물"
                    name="area"
                    value={formValues.area}
                    onChangeS={handleChange}
                    type="select"
                    required={true}
                    options={[
                      { label: "본관 4층", value: "본관 4층" },
                      { label: "본관 2층", value: "본관 2층" },
                      { label: "본관 G층", value: "본관 G층" },
                      { label: "신관 1층", value: "신관 1층" },
                      { label: "신관 2층", value: "신관 2층" },
                      { label: "창고", value: "창고" },
                    ]}
                  />
                  <GridFormField
                    label="층"
                    name="location"
                    value={formValues.location}
                    onChangeS={handleChange}
                    type="select"
                    required={true}
                    options={locationOptions}
                    disabled={isLocationDisabled}
                  />
                  <GridFormField
                    label="사용현황"
                    name="deviceStatus"
                    required={true}
                    value={formValues.deviceStatus}
                    onChangeS={handleChange}
                    type="select"
                    options={[
                      { label: "사용가능", value: "사용가능" },
                      { label: "보관중", value: "보관중" },
                      { label: "수리필요", value: "수리필요" },
                      { label: "수리중", value: "수리중" },
                    ]}
                  />
                </Grid>
              </Grid>
              {/* 사용자 정보 */}
              <Grid container>
                <Typography variant="h2" paddingY={2}>
                  사용자 정보
                </Typography>

                <Grid container gap={1}>
                  <GridFormField xs={4} label="사용자 구분" name="userType" value={formValues.userType} onChange={handleChange} />
                  <GridFormField
                    xs={4}
                    label="담당팀"
                    name="managingTeam"
                    value={formValues.managingTeam}
                    onChangeS={handleChange}
                    type="select"
                    options={[
                      { label: "선택안함", value: "" },
                      { label: "피부팀", value: "피부팀" },
                      { label: "간호팀", value: "간호팀" },
                      { label: "통증팀", value: "통증팀" },
                      { label: "코디팀", value: "코디팀" },
                    ]}
                  />
                </Grid>
              </Grid>
              {/* 비고 */}
              <Grid container>
                <Typography variant="h2" paddingY={2}>
                  비고
                </Typography>
                <Grid container>
                  <GridFormField label="비고" name="memo" value={formValues.memo} onChange={handleChange} multiline={true} rows={2} />
                </Grid>
              </Grid>
              {/* 제출 버튼 */}
              <Grid container textAlign={"center"} justifyContent={"center"} mt={2}>
                <Box>
                  <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                    제출
                  </Button>
                  <Button type="button" variant="contained" sx={{ backgroundColor: "lightgrey" }} onClick={HandleClose}>
                    취소
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceForm;
