import axios from "axios";
import { Employee } from "../models/EmployeeModel";

class EmployeeController {
  private apiUrl = "/api/v1/user";

  async fetchEmployee() {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const employeeResponse = await axios.post(
        `${this.apiUrl}/employee`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (employeeResponse.status === 200) {
        const data = employeeResponse.data;
        return data;
      }
    } catch (error: any) {
      console.error("Account Update failed", error);
      if (error.response.status === 404) {
        alert("직원정보를 불러오지 못했습니다.\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("직원정보를 불러오지 못했습니다\n권한이 없습니다.");
      }
    }
  }
  async resetPassword(userId: string) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const resetPasswordResponse = await axios.post(
        `${this.apiUrl}/resetPassword`,
        { userId: userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (resetPasswordResponse.status === 200) {
        const data = resetPasswordResponse.data;
        alert("비밀번호를 리셋하였습니다.\n신규 비밀번호 : amred3110");
        return data;
      }
    } catch (error: any) {
      console.error("Account Update failed", error);
      if (error.response.status === 404) {
        alert("비밀번호를 리셋하지 못했습니다.\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("비밀번호를 리셋하지 못했습니다.\n권한이 없습니다.");
      }
    }
  }

  async updatePassword(data: Employee, setEmployees: React.Dispatch<React.SetStateAction<Employee[]>>) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const resetPasswordResponse = await axios.post(`${this.apiUrl}/register`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (resetPasswordResponse.status === 200) {
        const data = resetPasswordResponse.data;
        setEmployees((prevNotices: any) => [...prevNotices, data]);
        // return data;
      }
    } catch (error: any) {
      console.error("Account Update failed", error.message);
      if (error.response.status === 404) {
        alert("비밀번호를 리셋하지 못했습니다.\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("비밀번호를 리셋하지 못했습니다.\n권한이 없습니다.");
      }
    }
  }
}

export const employeeController = new EmployeeController();
