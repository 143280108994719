import React,{useState,useEffect} from "react";
import { Grid, Typography, Paper, Box,IconButton, Checkbox, FormControlLabel, Select,MenuItem, ListItemText, SelectChangeEvent} from "@mui/material";
import ReservationCard from "./DailyOperationsCard/ReservationCard";
import VisitingCard from "./DailyOperationsCard/VisitingCard";
import ReturnCard from "./DailyOperationsCard/ReturnCard";
import WaitingCard from "./DailyOperationsCard/WaitingCard";
import { ScheduleData } from "../../models/HomeModel";
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { ScheduleController } from "../../controllers/HomeController";
import { Service } from "../../models/HomeModel";
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';

export interface CardProp {
    scheduleData : ScheduleData
    setScheduleDataList : React.Dispatch<React.SetStateAction<ScheduleData[]>>
}


const DailyOperations : React.FC = () => {
    const[isReservationFilter,setIsReservationFilter] = useState<boolean>(false);
    const[isVisitFilter,setIsVisitFilter] = useState<boolean>(false);
    const[isFinishFilter,setIsFinishFilter] = useState<boolean>(false);
    const[isResrvationMainBuilding, setIsResrvationMainBuilding] = useState<boolean>(true);
    const[isReservationNewBuilding, setIsReservationNewBuilding] = useState<boolean>(true);
    const[isVisitMainBuilding, setIsVisitMainBuilding] = useState<boolean>(true);
    const[isVisitNewBuilding, setIsVisitNewBuilding] = useState<boolean>(true);
    const[isFinishBuilding, setIsFinishMainBuilding] = useState<boolean>(true);
    const[isFinishNewBuilding, setIsFinishNewBuilding] = useState<boolean>(true);
    const allFloors = ["4층", "2층", "G층"];
    const [selectedReservationFloors, setSelectedReservationFloors] = useState<string[]>(allFloors);
    const [selectedVisitFloors, setSelectedVisitFloors] = useState<string[]>(allFloors);
    const [selectedFinishFloors, setSelectedFinishFloors] = useState<string[]>(allFloors);
    const handleIsReservationFilter = () =>{
        setIsReservationFilter(!isReservationFilter);
    }
    const handleIsVisitFilter = () =>{
        setIsVisitFilter(!isVisitFilter);
    }
    const handleIsFinishFilter = () =>{
        setIsFinishFilter(!isFinishFilter);
    }
    const handleIsReservationMainBuilding = (event : React.ChangeEvent<HTMLInputElement>) => {
        setIsResrvationMainBuilding(event.target.checked)
    }
    const handleIsReservationNewBuilding = (event : React.ChangeEvent<HTMLInputElement>) => {
        setIsReservationNewBuilding(event.target.checked)
    }
    const handleIsVisitMainBuilding = (event : React.ChangeEvent<HTMLInputElement>) => {
        setIsVisitMainBuilding(event.target.checked)
    }
    const handleIsVisitNewBuilding = (event : React.ChangeEvent<HTMLInputElement>) => {
        setIsVisitNewBuilding(event.target.checked)
    }
    const handleIsFinishMainBuilding = (event : React.ChangeEvent<HTMLInputElement>) => {
        setIsFinishMainBuilding(event.target.checked)
    }
    const handleIsFinishNewBuilding = (event : React.ChangeEvent<HTMLInputElement>) => {
        setIsFinishNewBuilding(event.target.checked)
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
          const file = event.target.files[0];
          ScheduleController.handleFileUpload(file);
        }
      };
    const handleSelectReservationFloors = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        if (value.includes("All")) {
            if (selectedReservationFloors.length === allFloors.length) {
                setSelectedReservationFloors([]);
            } else {
                setSelectedReservationFloors([...allFloors]);
            }
        } else {
            setSelectedReservationFloors(value);
        }
    };
    const handleSelectVisitFloors = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        if (value.includes("All")) {
            if (selectedVisitFloors.length === allFloors.length) {
                setSelectedVisitFloors([]);
            } else {
                setSelectedVisitFloors([...allFloors]);
            }
        } else {
            setSelectedVisitFloors(value);
        }
    };
    const handleSelectFinishFloors = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        if (value.includes("All")) {
            if (selectedFinishFloors.length === allFloors.length) {
                setSelectedFinishFloors([]);
            } else {
                setSelectedFinishFloors([...allFloors]);
            }
        } else {
            setSelectedFinishFloors(value);
        }
    };
    const [scheduleDataList, setScheduleDataList] = useState<ScheduleData[]>([]);
    const fetchSchedule = async () => {
        try {
        const scheduleResponse = await ScheduleController.fetchSchedule();
        const parsedScheduleResponse : ScheduleData[]=(scheduleResponse as unknown as any[]).map((item:any) =>{
            return {
            customer : item.customer,
            schedule : item.schedule,
            service: [
                ...item.service.filter((service : Service) => service.name === '방문'),
                ...item.service.filter((service : Service) => service.name !== '방문'),
            ]
            }
        });
        setScheduleDataList(parsedScheduleResponse);
        } catch (error) {
            console.error('Error fetching schedule info', error);
        }
    };
    useEffect(() => {
        fetchSchedule();
    }, []);

    return (
        <Grid container direction="column" sx={{ height: '100%',width:'100%',}}>
            <Grid container item sx={{ flexShrink: 0, position: 'relative', alignItems: 'center',display:'flex'}}>
                <Typography variant="h2" sx={{ textAlign: 'left',marginLeft : '4px' }}>
                    고객 현황
                </Typography>
                <Box sx={{   display: 'flex', flexWrap: 'wrap' }}>
                    <input
                    accept=".xlsx, .xls"
                    style={{ display: 'none'}}
                    id="upload-button-file"
                    type="file"
                    onChange={handleFileChange}
                    />
                    <label htmlFor="upload-button-file">
                        <IconButton component="span" sx={{padding:'4px',marginLeft:'4px'}}>
                            <CloudUploadRoundedIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </label>
                </Box>
            </Grid>
            <Paper sx={{ flexGrow: 1,height: 0,margin : '4px 0px 0px 4px', boxShadow: 3,borderRadius: '8px'}}>
                <Grid container item sx={{width:'100%',height:'75%'}}>
                    <Grid container item xs={4} direction="column" sx={{borderRight: '1px solid #b0b0b0', height: '100%', width: '100%'}}>
                        <Grid container item sx={{width: '100%', height: '30px', background: '#f0f0fb', padding: '4px', borderRadius: '8px 0px 0px 0px', borderBottom: '1px solid #b0b0b0',position:'relative'}}>
                            <Typography sx={{width:'100%',height:'100%',fontSize:'16px',fontWeight:'bold',display:'flex', justifyContent:'center',alignItems:'center'}}>
                                예약현황
                            </Typography>
                            <IconButton component="span" onClick={handleIsReservationFilter} sx={{padding:'4px',position:'absolute',right:0,bottom:0}}>
                                <FilterAltRoundedIcon sx={{fontSize:'16px'}}/>
                            </IconButton>
                        </Grid>
                        {isReservationFilter && (
                            <Grid container item sx={{width:'100%', height:'30px',padding:'0px 4px',borderBottom:'1px solid #b0b0b0'}}>
                                <Grid container item sx={{width:'auto', height:'30px'}}>
                                    <FormControlLabel control={<Checkbox checked = {isResrvationMainBuilding} onChange={handleIsReservationMainBuilding} sx={{padding:'2px', transform:'scale(0.8)'}}/>} label='본관' sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'},margin:'0px'}} />
                                </Grid>
                                <Grid container item sx={{width:'40%', height:'30px',display:'flex',alignItems:'center',marginLeft:'2px',maxWidth:'40%',marginRight:'4px'}}>
                                    <Select multiple value={selectedReservationFloors} onChange={handleSelectReservationFloors} sx={{height:'22px',fontSize:'12px',width:'100%'}} displayEmpty
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>층선택</em>;
                                            } else if (selected.length === 3) {
                                                return "All";
                                            } else if (selected) {
                                                return selected.join(', ');
                                            } else {
                                                return "층선택";
                                            }
                                          }}
                                        >
                                        <MenuItem value="All" sx={{height:'22px'}}>
                                            <Checkbox checked={selectedReservationFloors.length === allFloors.length} sx={{padding:'2px', transform:'scale(0.7)'}}/>
                                            <ListItemText primary="All" primaryTypographyProps={{ sx: { fontSize: '12px' } }} />
                                        </MenuItem>
                                        {allFloors.map((floor) => (
                                            <MenuItem key={floor} value={floor} sx={{height:'22px'}}>
                                                <Checkbox checked={selectedReservationFloors.indexOf(floor) > -1} sx={{padding:'2px', transform:'scale(0.7)'}} />
                                                <ListItemText primary={`${floor}`} primaryTypographyProps={{ sx: { fontSize: '12px' } }} />
                                            </MenuItem>
                                    ))}
                                    </Select>
                                </Grid>
                                <Grid container item sx={{width:'auto', height:'30px',}}>
                                    <FormControlLabel control={<Checkbox checked = {isReservationNewBuilding} onChange={handleIsReservationNewBuilding} sx={{padding:'2px', transform:'scale(0.8)'}}/>} label='신관' sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'},margin:'0px'}} />
                                </Grid>    
                            </Grid>
                        )}
                        <Grid container item direction="column" sx={{height: isReservationFilter? 'calc(100% - 60px)' : 'calc(100% - 30px)', overflowY: 'auto', width: '100%'}}>
                            <Grid container item sx={{height: 'auto',  width: '100%'}}>
                            {scheduleDataList
                            .filter((scheduleData) => ((scheduleData.schedule.status ==="beforeVisit" || scheduleData.schedule.status ==="cancel") &&
                                ((isResrvationMainBuilding && scheduleData.schedule.building === "본관" && (
                                    selectedReservationFloors.some(floor => scheduleData.schedule.floor.includes(floor))
                                )) || (isReservationNewBuilding && scheduleData.schedule.building === "신관") )))
                            .sort((a, b) => {
                                if (a.schedule.status === "beforeVisit" && b.schedule.status === "cancel") return -1;
                                if (a.schedule.status === "cancel" && b.schedule.status === "beforeVisit") return 1;
                                return 0;
                            })
                            .map((beforeVisitScheduleData) => (
                                <ReservationCard key={beforeVisitScheduleData.schedule.scheduleUuid} scheduleData={beforeVisitScheduleData} setScheduleDataList = {setScheduleDataList}/>
                            ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} direction="column" sx={{borderRight:'1px solid #b0b0b0',height:'100%',width:'100%'}}>
                        <Grid container item sx={{width: '100%', height: '30px', background: '#f0f0fb', padding: '4px', borderRadius: '8px 0px 0px 0px', borderBottom: '1px solid #b0b0b0',position:'relative'}}>
                            <Typography sx={{width:'100%',height:'100%',fontSize:'16px',fontWeight:'bold',display:'flex', justifyContent:'center',alignItems:'center'}}>
                                방문현황
                            </Typography>
                            <IconButton component="span" onClick={handleIsVisitFilter} sx={{padding:'4px',position:'absolute',right:0,bottom:0}}>
                                <FilterAltRoundedIcon sx={{fontSize:'16px'}}/>
                            </IconButton>
                        </Grid>
                        {isVisitFilter && (
                            <Grid container item sx={{width:'100%', height:'30px',padding:'0px 4px',borderBottom:'1px solid #b0b0b0'}}>
                                <Grid container item sx={{width:'auto', height:'30px'}}>
                                    <FormControlLabel control={<Checkbox checked = {isVisitMainBuilding} onChange={handleIsVisitMainBuilding} sx={{padding:'2px', transform:'scale(0.8)'}}/>} label='본관' sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'},margin:'0px'}} />
                                </Grid>
                                <Grid container item sx={{width:'40%', height:'30px',display:'flex',alignItems:'center',marginLeft:'2px',maxWidth:'40%',marginRight:'4px'}}>
                                    <Select multiple value={selectedVisitFloors} onChange={handleSelectVisitFloors} sx={{height:'22px',fontSize:'12px',width:'100%'}} displayEmpty
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>층선택</em>;
                                            } else if (selected.length === 3) {
                                                return "All";
                                            } else if (selected) {
                                                return selected.join(', ');
                                            } else {
                                                return "층선택";
                                            }
                                          }}
                                        >
                                        <MenuItem value="All" sx={{height:'22px'}}>
                                            <Checkbox checked={selectedVisitFloors.length === allFloors.length} sx={{padding:'2px', transform:'scale(0.7)'}}/>
                                            <ListItemText primary="All" primaryTypographyProps={{ sx: { fontSize: '12px' } }} />
                                        </MenuItem>
                                        {allFloors.map((floor) => (
                                            <MenuItem key={floor} value={floor} sx={{height:'22px'}}>
                                                <Checkbox checked={selectedVisitFloors.indexOf(floor) > -1} sx={{padding:'2px', transform:'scale(0.7)'}} />
                                                <ListItemText primary={`${floor}`} primaryTypographyProps={{ sx: { fontSize: '12px' } }} />
                                            </MenuItem>
                                    ))}
                                    </Select>
                                </Grid>
                                <Grid container item sx={{width:'auto', height:'30px',}}>
                                    <FormControlLabel control={<Checkbox checked = {isVisitNewBuilding} onChange={handleIsVisitNewBuilding} sx={{padding:'2px', transform:'scale(0.8)'}}/>} label='신관' sx={{'& .MuiFormControlLabel-label': {fontSize: '14px'},margin:'0px'}} />
                                </Grid>    
                            </Grid>
                        )}
                        <Grid container item direction="column" sx={{height: isVisitFilter? 'calc(100% - 60px)' : 'calc(100% - 30px)', overflowY: 'auto', width: '100%'}}>
                            <Grid container item sx={{height: 'auto',  width: '100%'}}>
                                {scheduleDataList
                                .filter((scheduleData) => ((scheduleData.schedule.status ==="onVisit") &&
                                    ((isVisitMainBuilding && scheduleData.schedule.building === "본관" && (
                                        selectedVisitFloors.some(floor => scheduleData.schedule.floor.includes(floor))
                                    )) || (isVisitNewBuilding && scheduleData.schedule.building === "신관") )))
                                .map((onVisitScheduleData) => (
                                    <VisitingCard key={onVisitScheduleData.schedule.scheduleUuid} scheduleData={onVisitScheduleData} setScheduleDataList = {setScheduleDataList}/>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} direction="column" sx={{height:'100%',width:'100%'}}>
                        <Grid container item sx={{width:'100%', height:'30px',background:'#f0f0fb',padding:'4px',borderRadius:'0px 8px 0px 0px',borderBottom:'1px solid #b0b0b0'}}>
                            <Typography sx={{width:'100%',height:'100%',fontSize:'16px',fontWeight:'bold',display:'flex', justifyContent:'center',alignItems:'center'}}>
                                귀가현황
                            </Typography>
                        </Grid>
                        <Grid container item direction="column" sx={{height: isVisitFilter? 'calc(100% - 60px)' : 'calc(100% - 30px)', overflowY: 'auto', width: '100%' }}>
                            {scheduleDataList
                                .filter((scheduleData) => ((scheduleData.schedule.status ==="return")))
                                .map((returnScheduleData) => (
                                    <ReturnCard/>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item sx={{width:'100%',height:'25%', borderTop : '1px solid #b0b0b0',}}direction="column" >
                    <Grid container item sx={{width:'100%', height:'30px',background:'#f0f0fb',padding:'4px',borderBottom:'1px solid #b0b0b0'}}>
                        <Typography sx={{width:'100%',height:'100%',fontSize:'16px',fontWeight:'bold',display:'flex', justifyContent:'center',alignItems:'center'}}>
                            대기중인 고객
                        </Typography>
                    </Grid>
                    <Grid container item sx={{height: 'calc(100% - 30px)', overflowY: 'auto', width: '100%'}}>
                        {scheduleDataList
                            .filter((scheduleData) => ((scheduleData.schedule.status ==="wait")))
                            .map((waitingSchedule) => (
                                <WaitingCard/>
                        ))}
                    </Grid>
                </Grid>

            </Paper>
        </Grid>
    );
}
export default DailyOperations