import React from "react";
import { Grid, Typography, Paper} from "@mui/material";

const DoctorSchedule : React.FC = () => {

    return (
        <Grid container direction="column" sx={{ height: '100%' }}>
            <Grid container item sx={{ flexShrink: 0, position: 'relative', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ textAlign: 'left',marginLeft : '4px' }}>
                    원장님 스케쥴
                </Typography>
            </Grid>
            <Paper sx={{ flexGrow: 1,height: 0,margin : '4px 0px 0px 4px', boxShadow: 3,borderRadius: '8px'}}>

            </Paper>
        </Grid>
    );
}
export default DoctorSchedule