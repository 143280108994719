import React from "react";
import "../../App.css";
import Sidebar from "../Sidebar";
import { CssBaseline, Box } from "@mui/material";
import { sidebarwidth } from "../Sidebar";

interface BasicStructureProps {
  children: React.ReactNode;
}

const BasicStructure: React.FC<BasicStructureProps> = ({ children }) => {
  return (
    <Box display={"flex"} sx={{ width: "100%", height: "100vh" }}>
      <CssBaseline />
      <Box marginRight={`${sidebarwidth}px`}>
        <Sidebar />
      </Box>
      <main style={{ flexGrow: 1 }}>{children}</main>
    </Box>
  );
};

export default BasicStructure;
