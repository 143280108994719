import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import { formatDate } from "../../util/Function";
import { Employee } from "../../models/EmployeeModel";

interface EmployeeListProps {
  Employees: Employee[];
  selectedEmployee: Employee | undefined;
  setSelectedEmployee: (employee: Employee) => void;
  setIsAddEmployee: (newState: boolean) => void;
}

const EmployeesList: React.FC<EmployeeListProps> = ({
  Employees,
  setSelectedEmployee,
  selectedEmployee,
  setIsAddEmployee,
}) => {
  const [isShowExitedEmployee, setIsShowExitedEmployee] = useState(false);
  const teamList = ["운영", "의사", "기획", "마케팅"];
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleRowClick = (employees: Employee) => {
    setSelectedEmployee(employees);
    setIsAddEmployee(false);
    setSelectedEmployee(employees);
  };

  useEffect(() => {
    if (Employees && selectedEmployee) {
      const updatedEmployeeList = Employees.find((employees) => employees.userId === selectedEmployee.userId);
      if (updatedEmployeeList) {
        setSelectedEmployee(updatedEmployeeList);
        // setSelectedEmployee(updatedDoctor);
      }
    }
  }, [Employees]);

  const handleTeamSelect = (event: SelectChangeEvent<string[]>) => {
    setSelectedValues(event.target.value as string[]);
  };

  const filteredEmployees =
    selectedValues.length > 0
      ? Employees.filter((employees) => selectedValues.includes(employees.team)) // team 필드를 기준으로 필터링
      : Employees;

  return (
    <Grid container direction="column" sx={{ height: "100%", flexGrow: 1 }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left", marginLeft: "4px" }}>
          직원 목록
        </Typography>
        <Grid sx={{ position: "absolute", right: 0, display: "flex", marginRight: "4px" }}>
          <Checkbox
            size="small"
            sx={{ padding: "0px", marginRight: "2px" }}
            checked={isShowExitedEmployee}
            onChange={(event) => setIsShowExitedEmployee(event.target.checked)}
          />
          <Typography sx={{ fontSize: "14px" }}>퇴사자 보기</Typography>
        </Grid>
        <Grid sx={{ position: "absolute", right: 100, display: "flex", marginRight: "4px" }}>
          <Select
            multiple
            displayEmpty
            value={selectedValues}
            onChange={handleTeamSelect}
            renderValue={() => <em>팀 선택</em>}
            sx={{
              height: "20px",
              fontSize: "12px",
            }}
          >
            {teamList.map((value, index) => (
              <MenuItem key={index} value={value} sx={{ fontSize: "12px", padding: "2px", margin: "0px 2px" }}>
                <Checkbox checked={selectedValues.indexOf(value) > -1} sx={{ transform: "scale(0.7)", padding: 0 }} />
                <ListItemText
                  primary={value}
                  primaryTypographyProps={{ style: { fontSize: "12px", padding: "0px", margin: "0px" } }}
                />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={() => setIsAddEmployee(true)}>
          <PersonAddAlt1RoundedIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </Grid>

      <Paper sx={{ flexGrow: 1, height: 0, margin: "4px 0px 0px 4px", boxShadow: 3, borderRadius: "8px" }}>
        <TableContainer sx={{ maxHeight: "100%", borderRadius: "8px 8px 0px 0px" }}>
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {
                padding: "4px",
                textAlign: "center",
                verticalAlign: "middle",
                fontSize: "11px",
              },
            }}
          >
            <TableHead sx={{ top: 0, position: "sticky" }}>
              <TableRow sx={{ backgroundColor: "#e1e1f5" }}>
                <TableCell
                  sx={{
                    width: "10%",
                    textAlign: "center",
                    paddingBottom: "0px",
                    fontWeight: "bold",
                    background: "#e1e1f5",
                  }}
                >
                  이름
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    textAlign: "center",
                    paddingBottom: "0px",
                    fontWeight: "bold",
                    background: "#e1e1f5",
                  }}
                >
                  아이디
                </TableCell>
                <TableCell
                  sx={{
                    width: "19%",
                    textAlign: "center",
                    paddingBottom: "0px",
                    fontWeight: "bold",
                    background: "#e1e1f5",
                  }}
                >
                  팀
                </TableCell>
                <TableCell
                  sx={{
                    width: "9%",
                    textAlign: "center",
                    paddingBottom: "0px",
                    fontWeight: "bold",
                    background: "#e1e1f5",
                  }}
                >
                  직급
                </TableCell>
                <TableCell
                  sx={{
                    width: "13%",
                    textAlign: "center",
                    paddingBottom: "0px",
                    fontWeight: "bold",
                    background: "#e1e1f5",
                  }}
                >
                  퇴사날짜
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEmployees
                .filter((employees) => isShowExitedEmployee || employees.exitDate === undefined)
                .map((employees) => {
                  const isActive = employees.exitDate === undefined; // exitDate가 undefined인지 확인

                  return (
                    <TableRow
                      key={employees.userId}
                      onClick={() => handleRowClick(employees)}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: selectedEmployee?.userId === employees.userId ? "#e8dff1" : "#ffffff",
                        "&:hover": {
                          backgroundColor: "#f0f0fb",
                        },
                      }}
                    >
                      <TableCell>{employees.name}</TableCell>
                      <TableCell>{employees.loginId}</TableCell>
                      <TableCell>{employees.team}</TableCell>
                      <TableCell>{employees.role}</TableCell>
                      <TableCell sx={{ color: isActive ? "#655dc6" : "rgba(0,0,0,0.87)" }}>
                        {isActive ? "재직중" : formatDate(employees.exitDate)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default EmployeesList;
