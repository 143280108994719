import React,{useState} from 'react';
import { Grid, Typography,Paper,Box,Popover,IconButton,Button} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useDoctor, Doctor } from '../../context/DoctorsContext';
import { doctorsColor} from './Doctors';
import { formatPhoneNumber, formatDate } from '../../util/Function';

interface DoctorDetailsProp{
    selectedDoctor : Doctor | undefined;
}
function calculateYearsMonthsDays(joiningDate: Date | undefined,referenceDate?: Date):string {
    const today = referenceDate || new Date();
    if (joiningDate === undefined || joiningDate === null){
        return "미입력"
    } else {
        
        let years = today.getFullYear() - new Date(joiningDate).getFullYear();
        let months = today.getMonth() - new Date(joiningDate).getMonth();
        let days = today.getDate() - new Date(joiningDate).getDate()+1;
        if (days < 0) {
        months -= 1; // 이전 달로 넘어감
        const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
        days += lastDayOfPreviousMonth;
        }
        if (months < 0) {
        years -= 1; // 이전 연도로 넘어감
        months += 12; // 12개월을 추가하여 정상화
        }
        return years === 0 ? (months===0?`${days}일` : `${months}개월 ${days}일`) :`${years}년 ${months}개월 ${days}일`;
    }
}

const DoctorDetails: React.FC<DoctorDetailsProp> = ({selectedDoctor}) => {
    const { doctors, updateDoctorColor } = useDoctor();
    const [color, setColor] = useState(selectedDoctor?.color); // 기본 색상 설정
    const handleColorChange = (newColor: string) => {
        if (selectedDoctor?.color === newColor){
            handleClose();
        } else{
            setColor(newColor);
            setColorStep("registerColor");
        }
    };
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const handleBoxClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleUpdateDoctorColor = () =>{
        if (!selectedDoctor?.doctorUuid) {
            // 예외 처리나 기본값 설정
            throw new Error('UUID is required');
          }
          updateDoctorColor({...selectedDoctor,color: color ? color : selectedDoctor.color,});
        handleClose();
    }
    const open = Boolean(anchorEl);
    const [colorStep,setColorStep] = useState("selectColor");
    return(
        <Grid container direction="column" sx={{ height: '100%' }}>
            <Grid container item sx={{ flexShrink: 0, position: 'relative', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ textAlign: 'left' }}>
                    원장님 세부사항
                </Typography>
            </Grid>
            <Paper sx={{ flexGrow: 1,height: 0, boxShadow: 3,borderRadius: '8px',marginTop:'4px',display:'flex'}}>
                {selectedDoctor===undefined ? (
                    <Typography variant='h2' sx={{justifyContent:'center',display:'flex',alignItems:'center',width:'100%'}}>조회하고 싶은 원장님을 목록에서 선택해 주세요</Typography>
                ):(
                    <>
                    <Grid container item xs={3} sx={{height:'100%',padding:'12px',background:'#f0f0fb',borderRadius:'8px 0px 0px 8px',textAlign:'center',fontSize:'14px'}} direction='column'>
                        <Grid item xs={5} sx={{height:'100%',overflow:'hidden',padding:'8px'}}>
                            <img 
                                src={"https://aac-amred-spaceos.s3.ap-northeast-2.amazonaws.com/doctors/"+selectedDoctor.imgUrl} 
                                alt={selectedDoctor.name} 
                                style={{ 
                                    width: '100%', 
                                    height: '100%', 
                                    objectFit: 'cover', // 이미지가 Grid 영역에 맞게 조정되도록 설정
                                    maxHeight: '100%', // 이미지의 최대 높이를 Grid의 높이에 맞게 제한
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{fontWeight:'bold'}}>
                            {selectedDoctor?.name}
                        </Grid>
                        <Grid container item xs={1}>
                            <Grid item xs={4} sx={{fontWeight:'bold'}}>
                                전화번호
                            </Grid>
                            <Grid item xs={8}>
                                {formatPhoneNumber(selectedDoctor?.phoneNumber)}
                            </Grid>
                        </Grid>
                        <Grid container item xs={1}>
                            <Grid item xs={4} sx={{fontWeight:'bold'}}>
                                생년월일
                            </Grid>
                            <Grid item xs={8}>
                                {selectedDoctor?.birthDate ? formatDate(new Date(selectedDoctor.birthDate)) : '날짜 없음'}
                            </Grid>
                        </Grid>
                        <Grid container item xs={1}>
                            <Grid item xs={4} sx={{fontWeight:'bold'}}>
                                입사날짜
                            </Grid>
                            <Grid item xs={8}>
                                {formatDate(selectedDoctor?.joiningDate)}
                            </Grid>
                        </Grid>
                        <Grid container item xs={1}>
                            <Grid item xs={4} sx={{fontWeight:'bold'}}>
                                재직기간
                            </Grid>
                            <Grid item xs={8}>
                                {formatDate(selectedDoctor?.exitDate) === ""  ? `${calculateYearsMonthsDays(selectedDoctor?.joiningDate)}째` : calculateYearsMonthsDays(selectedDoctor?.joiningDate,selectedDoctor?.exitDate)}
                            </Grid>
                        </Grid>
                        <Grid container item xs={1}>
                            <Grid item xs={4} sx={{fontWeight:'bold'}}>
                                퇴사날짜
                            </Grid>
                            <Grid item xs={8}>
                                {formatDate(selectedDoctor?.exitDate) === "" ?"재직중":formatDate(selectedDoctor?.exitDate)}
                            </Grid>
                        </Grid>
                        <Grid container item xs={1}>
                            <Grid item xs={4} sx={{fontWeight:'bold'}}>
                                색상
                            </Grid>
                            <Grid item xs={8}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', height: "100%" }}>
                                    {/* 색상 박스 */}
                                    <Box sx={{width: '20px',height: '20px',backgroundColor: selectedDoctor?.color,border: '1px solid #b0b0b0',
                                        borderRadius: '4px',textAlign: 'center',cursor: 'pointer'}} onClick={handleBoxClick}
                                    ></Box>
                                    {/* 팝업 */}
                                    <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
                                        transformOrigin={{ vertical: 'bottom',horizontal: 'left',}} 
                                        TransitionProps={{
                                            onExited: () => setColorStep("selectColor"),
                                    }}>
                                        {colorStep === "selectColor" ? (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 1, maxWidth:'180px' }}>
                                                {doctorsColor.map((color) => (
                                                    <IconButton key={color} onClick={() => {handleColorChange(color);}}
                                                        sx={{backgroundColor: color, width: '24px', height: '24px', margin: '4px', border: '1px solid #b0b0b0',
                                                            borderRadius: '4px','&:hover': {backgroundColor: color}}}
                                                    >
                                                    {selectedDoctor?.color === color && (
                                                        <CheckIcon sx={{color: '#000',fontSize: '16px',position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)'}}/>
                                                    )}
                                                    </IconButton>
                                                ))}
                                            </Box>
                                        ):(
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: 1, maxWidth:'300px' }}>
                                                <Typography variant='h4' sx={{justifyContent:'center',display:'flex',alignItems:'center',width:'100%'}}>
                                                    {selectedDoctor.name} 원장님 색상을 변경하시겠습니까?
                                                </Typography>
                                                <Box sx={{ display: 'flex',width:'100%',justifyContent:'center', margin:'8px 0px'}}>
                                                    기존색상
                                                    <Box sx={{width: '20px',height: '20px',backgroundColor: selectedDoctor?.color,border: '1px solid #b0b0b0',
                                                        borderRadius: '4px',textAlign: 'center',cursor: 'pointer', margin:'0px 12px 0px 4px'}} onClick={handleBoxClick}
                                                    ></Box>
                                                    변경색상
                                                    <Box sx={{width: '20px',height: '20px',backgroundColor: color,border: '1px solid #b0b0b0',
                                                        borderRadius: '4px',textAlign: 'center',cursor: 'pointer',marginLeft:'4px'}} onClick={handleBoxClick}
                                                    ></Box>
                                                </Box>
                                                <Box sx={{ display: 'flex',width:'100%',justifyContent:'center', }}>
                                                    <Button sx={{marginRight:'6px'}} onClick={handleUpdateDoctorColor}>
                                                        변경
                                                    </Button>
                                                    <Button sx={{marginLeft:'6px',}}onClick={handleClose}>
                                                        취소
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                        
                                    </Popover>
                                </Box>
                            </Grid>
                        </Grid>
                        </Grid>
                    <Grid container item xs={9} sx={{height:'100%'}}>
                        <Typography variant='h2' sx={{justifyContent:'center',display:'flex',alignItems:'center',width:'100%'}}>Comming Soon</Typography>
                    </Grid>
                    </>
                )}
                
            </Paper>

        </Grid>
    );
}
export default DoctorDetails