import React from "react";
import { Grid, Typography, Button} from "@mui/material";

const WaitingCard : React.FC = () => {

    return (
        <Grid container item sx={{width:'100%',height:'40px',background:'#fafafa', borderBottom:'1px solid #b0b0b0',padding:'8px'}}>
            <Grid container item xs={9.5}direction="column" justifyContent="center" >
                <Typography sx={{width:'100%',height:'18px',fontSize:'12px'}}>
                    이름(성별/나이) 전화번호
                </Typography>
            </Grid>
            <Grid container item xs={2.5} direction="column" justifyContent="center" alignItems="center">
                <Button sx={{ width: '60px', height: '24px', fontSize: '12px', textTransform: 'none', mb: "6px",minWidth:"unset"}}>
                    룸할당
                </Button>
            </Grid>
        </Grid>
    )
}
export default WaitingCard