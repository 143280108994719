import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import {authController} from '../controllers/AuthController'
import { Box, Typography } from '@mui/material';

const Login: React.FC = () => {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const { login,setUser} = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const loginResponse = await authController.handlelogin(loginId,password);
      if (loginResponse?.status === 200){
        const token = loginResponse.data.token;
        const userData = { ...loginResponse.data };
        delete userData.password;
        delete userData.token;
        setUser(userData);
        login(token);
        navigate('/home');
      }
    } catch (error : any) {
      if(error.response.status===404){
        alert("로그인 실패\n존재하지 않는 아이디입니다.")
      } 
      else if (error.response.status===401){
        alert("로그인 실패\n비밀번호가 옳지 않습니다.")
      }
    }
  };

  return (
    <Box sx={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
      <Typography sx={{width:'100%',fontSize:'3rem',textAlign:'center',marginBottom:'30px'}} variant='h1'>
        Amred Clinic
      </Typography>
      <form onSubmit={handleLogin} style={{alignItems:'center',gap:'20px',width:'100%',maxWidth:'400px',boxSizing:'border-box'}}>
        <Box sx={{width:'100%'}}>
          <Box sx={{alignItems:'center',border:'1px solid #ccc',borderRadius:'4px',padding:'10px',boxSizing:'border-box',marginBottom:'10px'}}>
            <FaUser style={{marginRight: '10px',color: '#888'}} />
            <input 
              style={{flex:1,border:'none',outline:'none',fontSize:'16px',width:'300px'}}
              type="text" 
              value={loginId} 
              onChange={(e) => setLoginId(e.target.value)} 
              placeholder="아이디를 입력하세요" 
              required 
            />
          </Box>
          <Box sx={{alignItems:'center',border:'1px solid #ccc',borderRadius:'4px',padding:'10px',boxSizing:'border-box',marginBottom:'10px'}}>
            <FaLock style={{marginRight: '10px',color: '#888'}} />
            <input 
              style={{flex:1,border:'none',outline:'none',fontSize:'16px',width:'300px'}}
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="비밀번호를 입력하세요" 
              required 
            />
          </Box>
        </Box>
        <button type="submit" className="login-button">Login</button>
      </form>
    </Box>    
  );
};

export default Login;

