import axios from 'axios';

class AuthController {
    private apiUrl = '/api/v1/auth';
    async handlelogin (loginId: string, password:string){
        try {
            const loginResponse = await axios.post(`${this.apiUrl}/login`,{loginId : loginId,password:password});
            if (loginResponse.status === 200){
                return loginResponse
            }
        } catch (error : any) {
            console.error('Login failed', error);
            if(error.response.status===404){
              alert("로그인 실패\n존재하지 않는 아이디입니다.")
            } 
            else if (error.response.status===401){
              alert("로그인 실패\n비밀번호가 옳지 않습니다.")
            }
        }
    }

}
export const authController = new AuthController();