import React from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface GridFormFieldProps {
  label: string;
  name: string;
  value: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | { name: string; value: unknown }>) => void;
  onChangeS?: (e: SelectChangeEvent<string>) => void; // SelectChangeEvent<string>로 타입 지정
  type?: "text" | "select";
  options?: Array<{ label: string; value: string }>; // Select의 경우 옵션을 받을 수 있도록
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  xs?: number;
  disabled?: boolean;
}

export const GridFormField: React.FC<GridFormFieldProps> = ({
  label,
  name,
  value,
  multiline,
  rows,
  onChange,
  onChangeS,
  xs,
  type = "text",
  options = [],
  required = false,
  disabled = false,
}) => {
  return (
    <Grid item flex={1} xs={xs}>
      {type === "text" ? (
        <TextField
          label={label}
          variant="outlined"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          fullWidth
          size="small"
          rows={rows}
          multiline={multiline}
        />
      ) : (
        <FormControl fullWidth size="small" required={required}>
          <InputLabel>{label}</InputLabel>
          <Select label={label} onChange={onChangeS} name={name} value={value!} disabled={disabled}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};
