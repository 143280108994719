import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import BasicStructure from "./BasicStructure";
import SearchBar from "./components/SearchBar";
import DevicesList from "./DevicesList";
import DeviceDetails from "./DeviceDetails";
import { DeviceProvider } from "../../context/DevicesContext";
import AddIcon from "@mui/icons-material/Add";
import DeviceForm from "./RegisterDevice";
import { DevicesLocalProvider } from "./context/DevicesLocalContext";

const Devices: React.FC = () => {
  const [openForm, setOpenForm] = useState<boolean>(false);

  // 장비 추가 폼 열기
  const HandleAddNewItemClick = () => {
    setOpenForm(true);
  };

  return (
    <BasicStructure>
      <DeviceProvider>
        <DevicesLocalProvider>
          {/* TODO: 탭이 들어가야 한다면 이곳에 */}
          <Grid container>
            <Grid container direction={"column"} padding={"20px"} wrap="nowrap" item xs={4} height={"100vh"}>
              <Grid item>
                <Box display={"flex"} alignItems="center">
                  <Typography variant="h2">장비 정보</Typography>
                  <IconButton sx={{ padding: 0 }} onClick={HandleAddNewItemClick}>
                    <AddIcon sx={{ fontSize: 24 }} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ height: "95%", display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}>
                <SearchBar />
                <DevicesList openForm={openForm} setOpenForm={setOpenForm} />
              </Grid>
            </Grid>
            <Grid item xs={8} height={"100vh"}>
              <Grid container direction={"column"} height={"100vh"} padding={"20px"}>
                <Grid item xs={12}>
                  {openForm ? <DeviceForm setOpenForm={setOpenForm} /> : <DeviceDetails />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DevicesLocalProvider>
      </DeviceProvider>
    </BasicStructure>
  );
};

export default Devices;
