import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Chip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { WorkSchedule2 } from "../../models/DoctorsModel";
import { doctorController } from "../../controllers/DoctorController";
import { useDoctor } from "../../context/DoctorsContext";

const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

// 저장하거나 취소할때 수정한 칩 배경색 초기화
const resetChipBackgrounds = () => {
  const chips = document.querySelectorAll(".chip");
  chips.forEach((chip) => {
    (chip as HTMLElement).style.backgroundColor = "#e1e1f5";
  });
};

const DoctorWeeklySchedule: React.FC = () => {
  const { doctors } = useDoctor();
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [workSchedules, setWorkSchedules] = useState<WorkSchedule2[]>([]);
  const [newWorkSchedules, setNewWorkSchedules] = useState<WorkSchedule2[]>([]);
  const [changedWorkSchedules, setChangedWorkSchedules] = useState<WorkSchedule2[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [draggingWorkDragId, setDraggingWorkDragId] = useState<number | null>(null);
  const [draggingFrom, setDraggingFrom] = useState<{
    date: Date;
    workType: string;
    doctorName?: string;
    doctorUuid: string;
  } | null>(null);
  const morningClosingTime = ["16:00", "19:00", "21:00", "19:00", "21:00", "19:00", "16:00"];
  const afternoonClosingTime = ["16:00", "21:00", "23:00", "21:00", "23:00", "21:00", "16:00"];
  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  const workTypes = ["morning", "afternoon", "off", "none"];

  const nextWeekDay = () => {
    const newDate = new Date(scheduleDate);
    newDate.setDate(scheduleDate.getDate() + 7);
    setScheduleDate(newDate);
  };

  const prevWeekDay = () => {
    const newDate = new Date(scheduleDate);
    newDate.setDate(scheduleDate.getDate() - 7);
    setScheduleDate(newDate);
  };

  const isChangedSchedule = (schedule: WorkSchedule2) => {
    return changedWorkSchedules.some((changedSchedule) => changedSchedule.dragId === schedule.dragId);
  };

  const fetchDoctorSchedule = async () => {
    try {
      const fetchDoctorScheduleResponse = await doctorController.fetchDoctorWeekSchedule(scheduleDate);
      const workingDoctors = doctors.filter((doctor) => !doctor.exitDate || doctor.exitDate <= new Date());
      const parsedDoctorSchedule: WorkSchedule2[] = [];

      workingDoctors.forEach((doctor) => {
        for (let i = 0; i < 7; i++) {
          const targetDate = new Date();
          targetDate.setDate(scheduleDate.getDate() - scheduleDate.getDay() + i);

          //doctorUuid가 있는 경우
          const existingSchedule = fetchDoctorScheduleResponse.find(
            (schedule: WorkSchedule2) =>
              schedule.doctorUuid === doctor.doctorUuid && isSameDay(new Date(schedule.date), targetDate)
          );
          console.log(existingSchedule);
          //dragId는 드레그를 위한 데이터
          const workSchedule: WorkSchedule2 = existingSchedule
            ? {
                ...existingSchedule,
                dragId: Number(`${i}${Math.floor(Math.random() * 1000)}`),
              }
            : {
                doctorWorkScheduleId: null,
                doctorUuid: doctor.doctorUuid,
                doctorName: doctor.name,
                date: targetDate,
                workStartTime: null,
                workEndTime: null,
                workType: "none",
                dragId: Number(`${i}${Math.floor(Math.random() * 1000)}`),
              };
          parsedDoctorSchedule.push(workSchedule);
        }
      });
      setWorkSchedules(parsedDoctorSchedule);
      setNewWorkSchedules(parsedDoctorSchedule);
    } catch (error) {
      console.error("Error fetching doctor schedule info", error);
    }
  };

  useEffect(() => {
    fetchDoctorSchedule();
  }, [scheduleDate, doctors]);

  // 현재 수정사항을 저장하고 편집 모드 종료
  const handleSaveChanges = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      //업데이트 한 내용을 저장
      const sanitizedSchedules = changedWorkSchedules.map(({ dragId, ...rest }) => rest);

      const updateData = await doctorController.updateDoctorSchedule(sanitizedSchedules);
      // api호출대신 저장하는 방식으로 구현
      if (Array.isArray(updateData)) {
        const filteredWorkSchedules = workSchedules.filter(
          (schedule) => !changedWorkSchedules.some((changed) => changed.dragId === schedule.dragId)
        );
        setWorkSchedules([...filteredWorkSchedules, ...updateData]);
        setNewWorkSchedules([...filteredWorkSchedules, ...updateData]);
      }
      setIsEdit(false);
      //resetChipBackgrounds();
      setChangedWorkSchedules([]);
    } catch (error) {
      console.error("Failed to update doctor schedule:", error);
    }
  };

  // 수정사항을 취소하고 원본 데이터로 복원
  const handleCancelChanges = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNewWorkSchedules([...workSchedules]);
    setIsEdit(false);
    //resetChipBackgrounds();
    setChangedWorkSchedules([]);
  };

  // 드래그 앤 드롭 이벤트 핸들러
  const handleDragStart = (
    event: React.DragEvent,
    dragId: number,
    date: Date,
    doctorUuid: string,
    workType: string,
    doctorName: string
  ) => {
    if (!isEdit) return;

    event.dataTransfer.effectAllowed = "move";
    setDraggingWorkDragId(dragId); // 여기에서 doctorWorkScheduleId를 사용합니다.

    setDraggingFrom({
      date: new Date(date),
      workType,
      doctorName,
      doctorUuid,
    });
    //(event.target as HTMLElement).style.backgroundColor = "gray";

    // 가상 드래그 이미지 생성
    const dragImage = document.createElement("div");
    dragImage.style.width = "58px";
    dragImage.style.height = "18px";
    dragImage.style.padding = "4px";
    dragImage.style.backgroundColor = "#e1e1f5";
    dragImage.style.borderRadius = "20px";
    dragImage.style.fontSize = "9px";
    dragImage.style.lineHeight = "24px";
    dragImage.style.color = "black";
    dragImage.style.position = "absolute";
    dragImage.style.display = "flex";
    dragImage.style.alignItems = "center";
    dragImage.style.justifyContent = "center";
    dragImage.style.textAlign = "center";
    dragImage.innerText = doctorName;
    document.body.appendChild(dragImage);
    event.dataTransfer.setDragImage(dragImage, 30, 0);

    // 드래그 종료 후 가상 이미지 제거
    setTimeout(() => {
      document.body.removeChild(dragImage);
    }, 0);
  };

  // 드래그 종료 시 처리
  const handleDrop = (event: React.DragEvent, targetDate: Date, targetWorkType: string) => {
    event.preventDefault();
    if (!isEdit) return;
    if (draggingFrom?.workType === targetWorkType) {
      return;
    }
    if (!setDraggingWorkDragId || !draggingFrom || !isEdit) return;
    if (draggingFrom.date.getDay() !== targetDate.getDay()) {
      return; // 날짜가 다를 경우 함수를 종료
    }

    // 드래그 중인 항목을 찾아서 제거하고 새로운 상태로 맨 뒤에 추가
    const draggingSchedule = newWorkSchedules.find((schedule) => schedule.dragId === draggingWorkDragId);
    const remainingSchedules = newWorkSchedules.filter((schedule) => schedule.dragId !== draggingWorkDragId);
    if (!draggingSchedule) return;

    const updatedSchedules = [
      ...remainingSchedules,
      {
        ...draggingSchedule,
        workType: targetWorkType as "morning" | "afternoon" | "off" | "none",

        date: targetDate,
      },
    ];

    //setNewWorkSchedules(updatedSchedules);
    setChangedWorkSchedules((prevSchedules) => {
      const foundWorkSchedule = workSchedules.find(
        (schedule) => schedule.dragId === draggingSchedule.dragId
      );
      // 수정시 기존 workType으로 오면 changedWorkSchedule에서 삭제
      if (foundWorkSchedule && foundWorkSchedule.workType === targetWorkType) {
        return prevSchedules.filter(
          (schedule) => schedule.dragId !== draggingSchedule.dragId
        );
      }
      const scheduleIndex = prevSchedules.findIndex(
        (schedule) => schedule.dragId === draggingSchedule.dragId
      );
      // 기존 changedWorkSchedule에 있으면 workType 업데이트
      if (scheduleIndex !== -1) {
        return prevSchedules.map((schedule, index) =>
          index === scheduleIndex
            ? {
                ...schedule,
                workType: targetWorkType as "morning" | "afternoon" | "off" | "none",
                date: targetDate,
              }
            : schedule
        );
      }
      // 새로운 변경사항이면 새로운 내용 추가
      return [
        ...prevSchedules,
        {
          ...draggingSchedule,
          workType: targetWorkType as "morning" | "afternoon" | "off" | "none",
          date: targetDate,
        },
      ];
    });
    setDraggingWorkDragId(null);
    setDraggingFrom(null);
  };

  const handleDragOver = (event: React.DragEvent) => {
    if (isEdit) {
      event.preventDefault();
    }
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Grid item sx={{ position: "absolute", left: 0, marginLeft: "4px", display: "flex", alignItems: "center" }}>
          <Typography variant="h2" sx={{ textAlign: "left", marginRight: "2px" }}>
            원장님 주간 스케줄
          </Typography>
          {isEdit ? (
            <>
              <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={(event) => handleSaveChanges(event)}>
                <FileDownloadOutlinedIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={(event) => handleCancelChanges(event)}>
                <CloseIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </>
          ) : (
            <IconButton sx={{ padding: "0px", marginLeft: "8px" }} onClick={() => setIsEdit(true)}>
              <EditIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          )}
        </Grid>
        <Grid item sx={{ margin: "0 auto", display: "flex", alignItems: "center" }}>
          <IconButton onClick={prevWeekDay} sx={{ padding: "4px" }}>
            <ArrowBackIcon sx={{ fontSize: "16px", padding: "0px" }} />
          </IconButton>
          <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
            {scheduleDate.getFullYear()}년 {scheduleDate.getMonth() + 1}월 {scheduleDate.getDate()}일
          </Typography>
          <IconButton onClick={nextWeekDay} sx={{ padding: "4px" }}>
            <ArrowForwardIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Grid>
      </Grid>

      <Paper sx={{ flexGrow: 1, height: 0, margin: "4px", boxShadow: 3, borderRadius: "8px" }}>
        <TableContainer sx={{ maxHeight: "100%", borderRadius: "8px 8px 0px 0px" }}>
          <Table
            stickyHeader
            sx={{
              "& .MuiTableCell-root": {
                padding: "4px",
                textAlign: "center",
                verticalAlign: "middle",
              },
            }}
          >
            <TableHead sx={{ top: 0, position: "sticky", background: "white" }}>
              <TableRow>
                <TableCell sx={{ width: "7%", fontWeight: "bold", borderRight: "1px solid #e0e0e0" }}>
                  출근 상태
                </TableCell>
                {daysOfWeek.map((day, idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      width: "12.5%",
                      textAlign: "center",
                      fontWeight: "bold",
                      background: scheduleDate.getDay() === idx ? "#e1e1f5" : "white",
                      borderRight: "1px solid #e0e0e0",
                    }}
                  >
                    {day}요일
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {workTypes.map((workType) => (
                <TableRow key={workType}>
                  <TableCell sx={{ verticalAlign: "middle", width: "7%", borderRight: "1px solid #e0e0e0" }}>
                    {workType === "morning" ? (
                      <>
                        오전출근 <br />
                        {/*(9:30~{morningClosingTime[scheduleDate.getDay()]})*/}
                      </>
                    ) : workType === "afternoon" ? (
                      <>
                        오후출근 <br />
                        {/*(12:00~{afternoonClosingTime[scheduleDate.getDay()]})*/}
                      </>
                    ) : workType === "off" ? (
                      "휴무"
                    ) : (
                      "미지정"
                    )}
                  </TableCell>
                  {new Array(7).fill(null).map((_, dayIdx) => {
                    const targetDate = new Date(scheduleDate);
                    targetDate.setDate(scheduleDate.getDate() - scheduleDate.getDay() + dayIdx);
                    const currentSchedules = isEdit ? newWorkSchedules : workSchedules;
                    const matchingSchedules = currentSchedules.filter(
                      (schedule) => schedule.workType === workType && isSameDay(new Date(schedule.date), targetDate)
                    );
                    return (
                      <TableCell
                        key={dayIdx}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, targetDate, workType)}
                        sx={{
                          verticalAlign: "middle",
                          width: "12.5%",
                          padding: "0px",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                          {matchingSchedules.length > 0
                            ? matchingSchedules.map((schedule :WorkSchedule2, idx:number) =>(
                              <Chip
                                key={`${schedule.doctorWorkScheduleId}-${schedule.doctorName}-${idx}`}
                                label={schedule.doctorName}
                                className="chip"
                                draggable={isEdit && (!isChangedSchedule(schedule))}
                                onDragStart={(event) =>
                                  handleDragStart(
                                    event,
                                    schedule.dragId ?? 0,
                                    schedule.date,
                                    schedule.doctorUuid,
                                    schedule.workType,
                                    schedule.doctorName
                                  )
                                }
                                sx={{
                                  cursor: isEdit && (!isChangedSchedule(schedule)) ? "move" : "default",
                                  fontSize: "9px",
                                  backgroundColor: isChangedSchedule(schedule) ? "#bdbdbd" : "#e1e1f5",
                                  lineHeight: "20px",
                                  height: "18px",
                                  fontWeight: "700",
                                }}
                              />
                            ))
                            : null}
                          {changedWorkSchedules.length > 0
                            ? changedWorkSchedules.filter(
                              (schedule) => schedule.workType === workType && isSameDay(new Date(schedule.date), targetDate)
                            )
                            .map((schedule: WorkSchedule2, idx: number) => {
                                return (
                                  <Chip
                                    key={`changed-${schedule.doctorWorkScheduleId}-${schedule.doctorName}-${idx}`}
                                    label={schedule.doctorName}
                                    className="chip"
                                    draggable={isEdit}
                                    onDragStart={(event) =>
                                      handleDragStart(
                                        event,
                                        schedule.dragId ?? 0,
                                        schedule.date,
                                        schedule.doctorUuid,
                                        schedule.workType,
                                        schedule.doctorName
                                      )
                                    }
                                    sx={{
                                      cursor: isEdit ? "move" : "default",
                                      fontSize: "9px",
                                      backgroundColor: "#655dc6",
                                      color:'white',
                                      lineHeight: "20px",
                                      height: "18px",
                                      fontWeight: "700",
                                    }}
                                  />
                                );
                              })
                            : null}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default DoctorWeeklySchedule;
