import axios from "axios";
import { User } from "../models/AccountModel";

class AccountController {
  private apiUrl = "/api/v1/user";

  async handleAccountInfoSaveClick(user: User, input: any) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const accountResponse = await axios.post(`${this.apiUrl}/update`, input, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (accountResponse.status === 200) {
        const data = accountResponse.data;
        return data;
      }
    } catch (error: any) {
      console.error("Account Update failed", error);
      if (error.response.status === 404) {
        alert("회원 정보 업데이트 실패\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("회원 정보 업데이트 실패\n비밀번호가 옳지 않습니다.");
      }
    }
  }
}
export const accountController = new AccountController();
