import React,{useEffect, useState} from 'react';
import Sidebar, {sidebarwidth} from '../Sidebar';
import { CssBaseline, Box, Grid} from '@mui/material';
import Notice from './Notice';
import WorkAttendance from './WorkAttendance';
import DailyOperations from './DailyOperations';
import DoctorSchedule from './DoctorSchedule';
import { formatDate } from '../../util/Function';
import { ScheduleData,Service } from '../../models/HomeModel';
import { useAuth } from '../../context/AuthContext';
import { ScheduleController } from '../../controllers/HomeController';



const Home: React.FC = () => {
  const { user } = useAuth();
  
  
  return (
    <Box sx={{ display: 'flex', position: 'relative',width:'100%',height:'100vh'}}>
      <CssBaseline />        
      <Box sx={{ display: 'flex', position: 'sticky',left:0,zIndex:100, width: '60px', height:'100%', backgroundColor: 'white' }}>
        <Sidebar />
      </Box>
      <Grid container padding='10px 10px 10px 0px' width='calc(100% - 60px)' height="100%" >
        <Grid container item xs={2.5} direction="column" width='100%' height='100%'>
          <Grid container item xs = {6} width='100%' height='50%' >
            <Notice/>
          </Grid>
          <Grid container item xs = {6} width="100%" height ="50%">
            <WorkAttendance/>
          </Grid>
        </Grid>
        <Grid container item xs={7} sx={{padding:'0px 8px'}}>
          <DailyOperations/>
        </Grid>
        <Grid container item xs={2.5}>
          <DoctorSchedule/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;