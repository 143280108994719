// src/context/AuthContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import {User} from '../models/AccountModel'
import { authController } from '../controllers/AuthController';

interface AuthContextType {
    isAuthenticated: boolean;
    loading: boolean;
    user : User | undefined;
    setUser : (user:User) =>void;
    login: (token: string) => void;
    logout: () => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [user,setUser] = useState<User>();
    const location = useLocation();  // 현재 경로 확인
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.get('/api/v1/auth/validateToken', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                if (response.status === 200) {
                    setIsAuthenticated(true);
                    setUser(response.data);
                    if (location.pathname === '/employee') {
                        console.log(response.data.authRole)
                        if (response.data.authRole !== "developer" && response.data.authRole !== "admin") {
                            navigate('/home');
                        }
                    }
                } else {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            }).catch(() => {
                localStorage.removeItem('token');
                navigate('/login');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
            navigate('/login');
        }
    }, [navigate]);

    const login = (token: string) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        navigate('/home');
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/login')
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, user,setUser, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};