import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Device } from "../models/DevicesModel";
import { devicesController } from "../controllers/DevicesController";
interface DeviceContextType {
  deviceList: Device[];
  addDevice: (newDevice: Device,imgae?:File) => Promise<boolean>;
  updateDevice: (newDevice: Device) => Promise<boolean>;
}

export const initialDevice: Device = {
  area: "",
  asContact: "",
  codeNumber: "",
  deviceCategory: "",
  deviceCompany: "",
  deviceId: null,
  deviceName: "",
  deviceStatus: "",
  deviceUuid: null,
  imgUrl: null,
  location: "",
  managingTeam: "",
  memo: "",
  serialNumber: "",
  title: "",
  userType: "",
};

const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

export const DeviceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [deviceList, setDeviceList] = useState<Device[]>([]);

  // 서버에서 장비 목록 가져오기
  useEffect(() => {
    const fetchDevice = async () => {
      try {
        const response = await devicesController.handleFetchDeviceList();
        setDeviceList(response);
      } catch (error) {
        console.error("Failed to fetch device data:", error);
      }
    };

    fetchDevice();
  }, []);

  // 빈 문자열을 null로 변환
  const replaceEmptyWithNull = <T extends Record<string, any>>(obj: T): T => {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === "" ? null : value])) as T;
  };

  // 폼 데이터 변환 함수
  const createFormData = (data: Device,img? : File) => {
    const formData = new FormData();
    formData.append("deviceDto", new Blob([JSON.stringify(data)], { type: "application/json" }));
    if (img){
      formData.append("img",img);
    }
    return formData;
  };

  // 장비 추가
  const addDevice = async (newDevice: Device,img? : File) => {
    try {
      const cleanedData = replaceEmptyWithNull(newDevice);
      const formData = createFormData(cleanedData,img);
      const response = await devicesController.handleCreateDevice(formData);
      if (response!.status === 200) {
        setDeviceList((prevDevices) => [...prevDevices, { ...newDevice, deviceUuid: response!.data.deviceUuid }]);
        alert("정상적으로 추가되었습니다.");
        return true;
      } else {
        alert("오류가 생겼습니다. 잠시 후에 다시 시도하거나 운영개발팀에 문의하세요.");
      }
    } catch (error) {
      console.error("Failed to add device:", error);
    }
    return false;
  };

  // 장비 수정
  const updateDevice = async (updateDevice: Device) => {
    try {
      const response = await devicesController.handleUpdateDevice(updateDevice);
      if (response!.status === 200) {
        alert("정상적으로 수정되었습니다.");
        return true;
      } else {
        alert("오류가 생겼습니다. 잠시 후에 다시 시도하거나 운영개발팀에 문의하세요.");
      }
    } catch (error) {
      console.error("Failed to update device:", error);
    }
    return false;
  };

  return (
    <DeviceContext.Provider
      value={{
        deviceList,
        addDevice,
        updateDevice,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

// 커스텀 Hook으로 context 사용
export const useDevice = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error("useDevice must be used within a DeviceProvider");
  }
  return context;
};
