import { Building } from "../models/RoomModel";
export function formatPhoneNumber(phoneNumber: string | undefined): string {
  if (phoneNumber === undefined || phoneNumber === null) {
    return "";
  } else {
    const phonePattern = /(\d{3})(\d{4})(\d{4})/;
    const match = phoneNumber.match(phonePattern);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }
}
export const formatDate = (date: Date | null | undefined): string => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formTime = (createdAt: Date): string => {
  const date = new Date(createdAt);
  return date.toLocaleTimeString("ko-KR", {
    hour: "numeric",
    minute: "2-digit",
    second : '2-digit',
    hour12: true,
  });
};
export const isWithinTenMinutes = (createdAt: Date): boolean => {
  const createdTime = new Date(createdAt).getTime();
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - createdTime;
  return timeDiff <= 600000; // 10분 이내면 true 반환
}

export const calculateTimeDifference = (time : string) => {
  const now = new Date(); // 현재 시간
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const expectedVisitDate = new Date();
  expectedVisitDate.setHours(hours, minutes, seconds, 0);
  const timeDifferenceInMs = expectedVisitDate.getTime() - now.getTime();
  return timeDifferenceInMs
};

export const parseBuildingsToFloors = (buildings: Building[], selectedBuilding: string): { id: string, name: string }[] => {
  const building = buildings.find(building => building.name === selectedBuilding);
  if (!building) {
    return []; // 혹은 빈 배열 [] 반환 가능
  }
  return building.floors.map(floor => ({
    id: floor.id,
    name: floor.name
  }));
};
export const parseBuildingsToRooms = (buildings: Building[], selectedBuilding: string,selectedFloor:string): { id: string, name: string }[] => {
  const floor = buildings.find(building => building.name === selectedBuilding)?.floors.find(floor => floor.name ===selectedFloor);
  if (!floor) {
    return []; // 혹은 빈 배열 [] 반환 가능
  }
  return floor.rooms.map(room =>({
      id : room.id,
      name : room.name
  }));
};