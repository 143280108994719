import React, { useEffect, useState } from "react";
import "../../App.css";
import Sidebar from "../Sidebar";
import { CssBaseline, Box, Grid } from "@mui/material";
import EmployeeList from "./EmployeeList";
import EmployeeDetails from "./EmployeeDetails";
import RegisterEmployee from "./RegisterEmployee";
import { Employee } from "../../models/EmployeeModel";
import { employeeController } from "../../controllers/EmployeeController";

const mockEmmployees: Employee[] = [];

const EmployeePage: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>(mockEmmployees);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [isAddEmployee, setIsAddEmployee] = useState<boolean>(false);
  const fetchEmployee = async () => {
    try {
      const scheduleResponse = await employeeController.fetchEmployee();
      setEmployees(scheduleResponse);
    } catch (error) {
      console.error("Error fetching schedule info", error);
    }
  };
  useEffect(() => {
    fetchEmployee();
  }, []);

  return (
    <Box sx={{ display: "flex", position: "relative", width: "100%", height: "100vh" }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          position: "sticky",
          left: 0,
          zIndex: 100,
          marginRight: "70px",
          backgroundColor: "white",
        }}
      >
        <Sidebar />
      </Box>
      <Grid container direction="row" sx={{ height: "100%", padding: "20px 20px 20px 0px" }}>
        <Grid container item xs={3} sx={{ height: "100%" }}>
          <EmployeeList
            setSelectedEmployee={setSelectedEmployee}
            selectedEmployee={selectedEmployee}
            setIsAddEmployee={setIsAddEmployee}
            Employees={employees}
          />
        </Grid>
        <Grid container item xs={9} sx={{ height: "100%", paddingLeft: "20px" }}>
          {isAddEmployee ? (
            <RegisterEmployee
              isAddEmployee={isAddEmployee}
              setIsAddEmployee={setIsAddEmployee}
              setEmployees={setEmployees}
              employees={employees}
            />
          ) : (
            <EmployeeDetails selectedEmployee={selectedEmployee} setEmployees={setEmployees} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeePage;
