import React from 'react';
import '../../App.css';
import Sidebar, {sidebarwidth} from '../Sidebar';
import { CssBaseline, Box } from '@mui/material';
import CalendarTable from './CalendarTable';

const Schedule: React.FC = () => {  

  return (
    <Box sx={{ display: 'flex', position: 'relative',width:'fit-content'}}>
      <CssBaseline/>
      <Box sx={{ display: 'flex', position: 'sticky',left:0,zIndex:100, paddingRight: '70px', backgroundColor: 'white' }}>
        <Sidebar />
      </Box>
      <Box component="main"sx={{ justifyContent:'center',position:'relative'}}>
          <CalendarTable />
      </Box>
    </Box>
  );
};

export default Schedule;