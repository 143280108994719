import React from 'react';
import '../../App.css';
import Sidebar, {sidebarwidth} from '../Sidebar';
import { CssBaseline, Box } from '@mui/material';

const Customers: React.FC = () => {
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1,
          bgcolor: 'background.default',
          display : 'flex',
          justifyContent:'center',
          marginLeft: sidebarwidth + "px",}}
      >
          <div className='App-header'>
            <h1>Customers</h1>
            <p>Comming Soon!</p>
          </div>
      </Box>
    </Box>
  );
};

export default Customers;