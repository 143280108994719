
export interface Building{
    id : string;
    name : string;
    floors : Floor[];
}
export interface Floor{
    id : string;
    name : string;
    rooms : Room[];
}
export interface Room{
    id : string;
    name : string;
}
export const buildings: Building[] = [
    {
      id: '본관',
      name: '본관',
      floors: [
        {
          id: '4층',
          name: '4층',
          rooms: [
            { id: '4미지정', name: '4미지정' },
            { id: '4A', name: '4A' },
            { id: '4M', name: '4M' },
            { id: '4R', name: '4R' },
            { id: '4E', name: '4E' },
            { id: '4D', name: '4D' },
            { id: '4F시술실1', name: '4F시술실1' },
            { id: '4F시술실2', name: '4F시술실2' },
            { id: '4F상담실1', name: '4F상담실1' },
            { id: '4F상담실2', name: '4F상담실2' },
            { id: '4F진료실1', name: '4F진료실1' },
            { id: '4F진료실2', name: '4F진료실2' },
          ],
        },
        {
          id: '2층',
          name: '2층',
          rooms: [
              { id: '2미지정', name: '2미지정' },
              { id: '2A', name: '2A' },
              { id: '2M', name: '2M' },
              { id: '2R', name: '2R' },
              { id: '2E', name: '2E' },
              { id: '2D', name: '2D' },
              { id: 'ABOVE', name: 'ABOVE' },
              { id: '2F상담실', name: '2F상담실' },
              { id: '2F시술실', name: '2F시술실' },
          ],
        },
        {
          id: 'G층',
          name: 'G층',
          rooms: [
              { id: 'G미지정', name: 'G미지정' },
              { id: 'GA', name: 'GA' },
              { id: 'GM', name: 'GM' },
              { id: 'GR', name: 'GR' },
              { id: 'GE', name: 'GE' },
              { id: 'GD', name: 'GD' },
              { id: 'HORIZON', name: 'HORIZON' },
              { id: 'GF레이저실', name: 'GF레이저실' },
              { id: 'GF시술실', name: 'GF시술실' },
              { id: 'GF대기실', name: 'GF대기실' },
          ],
        },
      ],
    },
    {
      id: '신관',
      name: '신관',
      floors: [
        {
          id: '2층',
          name: '2층',
          rooms: [
              { id: 'LA', name: 'LA' },
              { id: 'LM', name: 'LM' },
              { id: 'LR', name: 'LR' },
              { id: 'LE', name: 'LE' },
              { id: 'LD', name: 'LD' },
          ],
        },
        {
          id: '1층',
          name: '1층',
          rooms: [
              { id: 'L미지정', name: 'L미지정' },
              { id: 'L상담실1', name: 'L상담실1' },
              { id: 'L상담실2', name: 'L상담실2' },
              { id: 'L상담실3', name: 'L상담실3' },
              { id: 'L대기실', name: 'L대기실' },
          ],
        },
      ],
    },
  ];
  export const rooms = buildings.reduce((acc, building) => {
    building.floors.forEach(floor => {
      floor.rooms.forEach(room => {
        acc.push({ id: room.id, name: room.name });
      });
    });
    return acc;
  }, [] as { id: string, name: string }[]);