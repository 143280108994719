import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Button, Tooltip, TextareaAutosize } from "@mui/material";
import { useDoctor } from "../../context/DoctorsContext";
import { workAttendanceController } from "../../controllers/HomeController";
import { WorkAttendanceItem } from "../../models/HomeModel";

const WorkAttendance: React.FC = () => {
  const { doctors } = useDoctor();
  const [workAttendance, setWorkAttendance] = useState<WorkAttendanceItem>();
  const [newWorkAttendance, setNewWorkAttendance] = useState<string>(""); // 빈 문자열로 초기화
  const [isEdit, setIsEdit] = useState(false);

  // 임시 상태에 입력된 값을 저장
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewWorkAttendance(e.target.value); // 편집 중인 내용은 임시로 저장
  };

  // 수정 내용을 저장
  const handleSave = () => {
    if (workAttendance) {
      workAttendanceController.updateWorkAttendance(
        newWorkAttendance,
        workAttendance?.workAttendanceId,
        setWorkAttendance
      );
      setIsEdit(false);
    }
  };

  // 취소 버튼 클릭 시 편집을 취소하고 원래 상태로 돌아가기
  const handleCancel = () => {
    setNewWorkAttendance(workAttendance?.text || ""); // 원래 텍스트 값으로 복구
    setIsEdit(false); // 편집 모드 해제
  };

  // 편집 버튼 클릭 시 편집 모드로 진입
  const handleEdit = () => {
    setNewWorkAttendance(workAttendance?.text || ""); // 현재 텍스트를 임시로 저장
    setIsEdit(true); // 편집 모드 활성화
  };

  useEffect(() => {
    const fetchWorkAttendance = async () => {
      try {
        const fetchedWorkAttendance = await workAttendanceController.fetchWorkAttendance();
        setWorkAttendance(fetchedWorkAttendance);
      } catch (error) {
        console.error("Error fetching work attendance:", error);
      }
    };
    fetchWorkAttendance(); // 비동기 함수 호출
  }, []); // 빈 배열로 마운트 시에만 실행

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center", height: "24px" }}>
        <Typography variant="h2" sx={{ textAlign: "left", marginLeft: "4px" }}>
          금일 출근 명부
        </Typography>
      </Grid>
      <Paper
        sx={{
          flexGrow: 1,
          margin: "4px 0px 0px 4px",
          boxShadow: 3,
          borderRadius: "8px",
          overflowY: "auto",
          padding: "15px",
          height: "calc(100% - 40px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid sx={{ fontSize: "12px", paddingBottom: "15px", flexShrink: 0 }}>
          <span>
            {`<원장님>`} {doctors.length}명
          </span>
          <br />
          <span>{doctors.map((doctor) => doctor.name).join(", ")}</span>
        </Grid>

        {isEdit ? (
          <>
            <TextareaAutosize
              minRows={3}
              maxRows={10} // 최대 행의 개수를 설정하여 스크롤이 생기게 함
              placeholder="출근 명부를 입력해주세요."
              value={newWorkAttendance} // newWorkAttendance 값을 사용
              onChange={handleInputChange}
              style={{
                flexGrow: 1,
                width: "100%",
                padding: "12px 10px",
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Roboto",
                borderRadius: "10px",
                border: "1px solid #ccc",
                overflowY: "auto",
                resize: "none",
                whiteSpace: "pre-line",
              }}
            />

            <Grid container spacing={1} sx={{ marginTop: "5px", justifyContent: "flex-end", flexShrink: 0 }}>
              <Grid item>
                <Button sx={{ height: "25px" }} variant="contained" color="primary" onClick={handleSave}>
                  저장
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ height: "25px", backgroundColor: "lightgray" }}
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel} // 취소 시 원래 상태로 복원
                >
                  취소
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Tooltip title="클릭 시 수정할 수 있습니다" placement="right-end" arrow followCursor>
            <Typography
              sx={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                fontSize: "12px",
                padding: "10px",
                flexGrow: 1,
                cursor: "pointer",
                whiteSpace: "pre-line",
                color:'#bdbdbd'
              }}
              onClick={handleEdit} // 편집 모드로 전환
            >
              {(workAttendance === undefined || workAttendance?.text === "") ? "출근 명부를 입력해주세요." : workAttendance?.text}
            </Typography>
          </Tooltip>
        )}
      </Paper>
    </Grid>
  );
};

export default WorkAttendance;
