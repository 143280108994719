export interface Employee {
  userId: string;
  userUuid: string;
  loginId: string;
  password: string;
  name: string;
  phoneNumber: string;
  team: string;
  role: string;
  authRole: string;
  birthDate?: Date;
  joiningDate: Date;
  isExited?: boolean;
  isActive?: true;
  exitDate?: Date | undefined;
  imgUrl: string;
}

export const initialEmployee: Employee = {
  userId: "",
  userUuid: "",
  loginId: "",
  password: "",
  name: "",
  phoneNumber: "",
  team: "",
  role: "",
  authRole: "",
  birthDate: new Date(),
  joiningDate: new Date(),
  imgUrl: "",
};
