import React,{useEffect,useState} from "react";
import { Grid, Typography, Button, Tooltip,Box,Modal,TextField,MenuItem} from "@mui/material";
import { formatPhoneNumber } from "../../../util/Function";
import { calculateTimeDifference,parseBuildingsToFloors,parseBuildingsToRooms } from "../../../util/Function";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ScheduleController } from "../../../controllers/HomeController";
import { CardProp } from "../DailyOperations";
import { buildings } from "../../../models/RoomModel";



export const parsingTimeDifference = (timeDifferenceInMs: number) => {
    const absTimeDiff = Math.abs(timeDifferenceInMs);
    const hours = Math.floor(absTimeDiff / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((absTimeDiff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((absTimeDiff % (1000 * 60)) / 1000).toString().padStart(2, '0');
  
    if (timeDifferenceInMs < 0) {
        if (hours === "00"){
            return `(+${minutes}:${seconds})`;      
        }
        else {
            return `(+${hours}:${minutes}:${seconds})`;      
        }
      
    } else {
        if (hours === "00"){
            return `(-${minutes}:${seconds})`;      
        }
        else {
            return `(-${hours}:${minutes}:${seconds})`;      
        }
    }
  };

const ReservationCard : React.FC<CardProp> = ({scheduleData,setScheduleDataList}) => {
    const [timeDifference,setTimeDifference] = useState<number>(0);
    const [building,setBuilding] = useState(scheduleData.schedule.building);
    const [floor,setFloor] = useState(scheduleData.schedule.floor);
    const [room,setRoom] = useState(scheduleData.schedule.room);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeDifference(calculateTimeDifference(scheduleData.schedule.expectedVisitTime));
        }, 1000);
        return () => clearInterval(intervalId);
    }, [scheduleData.schedule.expectedVisitTime]);
    const handleVisitCustomer = async (scheduleId: string,building? : string, floor? : string, room? : string) => {
        const updatedSchedule = await ScheduleController.visitCustomer(scheduleId,building,floor,room);
        setScheduleDataList(prevData =>
            prevData.map(item =>
              item.schedule.scheduleId === updatedSchedule.scheduleId
                ? { ...item, schedule: { ...item.schedule, ...updatedSchedule } }
                : item
            )
        );
    };
    const handleCancelSchedule = async (scheduleId: string) => {
        const updatedSchedule = await ScheduleController.cancelSchedule(scheduleId);
        setScheduleDataList(prevData =>
            prevData.map(item =>
              item.schedule.scheduleId === updatedSchedule.scheduleId
                ? { ...item, schedule: { ...item.schedule, ...updatedSchedule } }
                : item
            )
          );
    };

    const [visitModalOpen, setVisitModalOpen] = useState(false);  // 모달 상태 관리
    const [inputValue, setInputValue] = useState('');  // 입력 값 관리

    const handleOpenVisitModal = () => setVisitModalOpen(true);   // 모달 열기
    const handleCloseVisitModal = () => setVisitModalOpen(false); // 모달 닫기



    return (
        <Grid container item sx={{ width:'100%',height:'70px',background: scheduleData.schedule.status ==="beforeVisit"? '#fafafa' : '#E0E0E0' , borderBottom:'1px solid #b0b0b0',padding:'8px'}}>
            <Grid container item xs={9.5}>
                <Box width='100%' display='flex' overflow='hidden' position="relative">
                    <Tooltip title={`${scheduleData.customer.name} / 차트번호:${scheduleData.customer.vegasId}`} arrow followCursor placement="right">
                        <Typography sx={{color:'#655dc6',fontWeight:'bold',width: 'auto',height: '18px',fontSize: '12px',maxWidth: '70%',overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis'}}>
                            {scheduleData.customer.name}
                        </Typography>
                    </Tooltip>
                    <Typography sx={{width:'20%',height:'18px',fontSize:'12px',maxWidth:'20%'}}>
                        ({scheduleData.customer.gender === "M"? "남" : "여"}/{scheduleData.customer.age})
                    </Typography>
                    <Box sx={{width:'auto',height:'18px',fontSize:'14px',maxWidth:'10%',position:'absolute',right:'4px'}}>
                        <InfoRoundedIcon sx={{color:'#bdbdbd',fontSize:'14px'}}/>
                    </Box>
                </Box>
                <Typography sx={{width:'100%',height:'18px',fontSize:'12px'}}>
                    <Typography component="span"  sx={{width:'auto',height:'18px',fontSize:'12px'}}>
                        {scheduleData.schedule.building}{scheduleData.schedule.floor}
                    </Typography>
                    <Typography component="span" sx={{width:'auto',height:'18px',fontSize:'12px',marginLeft:'4px'}}>
                        {formatPhoneNumber(scheduleData.customer.phoneNumber)}
                    </Typography>
                </Typography>
                <Typography sx={{width:'auto',height:'18px',fontSize:'12px'}}>
                    {scheduleData.schedule.expectedVisitTime.slice(0,5)} 방문예정
                    {((scheduleData.schedule.status !== "cancel") && (timeDifference<(1000*60*10))) &&  (
                        <Typography
                        component="span"
                        sx={{
                            fontSize:'12px',
                            color: timeDifference > 0 ? 'blue' : '#ff4d4d',
                            marginLeft: '4px',
                        }}
                        >
                        {parsingTimeDifference(timeDifference)}
                        </Typography>
                    )}
                    {(scheduleData.schedule.status === "cancel") &&   (
                        <Typography
                        component="span"
                        sx={{
                            fontSize:'12px',
                            fontWeight:'600',
                            marginLeft: '4px',
                        }}
                        >
                        취소된 고객
                        </Typography>
                    )}
                </Typography>
            </Grid>
            <Grid container item xs={2.5} direction="column" justifyContent="center" alignItems="center">
                <Button sx={{ width: '100%', height: '24px', fontSize: '12px', textTransform: 'none', mb: "6px",minWidth:"unset"}} onClick={handleOpenVisitModal}>
                    방문
                </Button>
                <Modal open={visitModalOpen} onClose={handleCloseVisitModal} sx={{width:'100%',height:'100vh',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <Box sx={{ width: "440px", p: "16px", backgroundColor: 'white',borderRadius:'8px',height:'auto'}}>
                        <Typography variant="h4" sx={{textAlign:'center',width:'100%',height:'20px'}}>
                            {scheduleData.customer.name} 고객님의 룸을 할당하시겠습니까?
                        </Typography>
                        <Grid container item sx={{width:'100%',padding:'16px 0px',height:'80px'}}>
                            <Grid item xs={4}>
                                <TextField variant="standard" select label="본관/신관"
                                    value={building}
                                    sx={{ width: '100%', fontSize: '16px', textAlign: 'center'}}
                                    onChange={(e) => setBuilding(e.target.value)}
                                    fullWidth
                                >
                                {buildings.map((building) => (
                                    <MenuItem key={building.id} value={building.name}>
                                    {building.name}
                                    </MenuItem>
                                ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                            <TextField select variant="standard" label="층 선택"
                                sx={{ width: '100%', fontSize: '16px', textAlign: 'center'}}
                                value={floor}
                                onChange={(e) => setFloor(e.target.value)}
                                fullWidth
                                disabled={(building === "")}
                            >
                            {parseBuildingsToFloors(buildings,building||'').map((floor)=> (
                                <MenuItem key={floor.id} value={floor.name}>
                                    {floor.name}
                                </MenuItem>
                            ))}
                            </TextField>
                            </Grid>
                            <Grid item xs={4}>
                            <TextField variant="standard" select label="룸 선택"
                                value={room}
                                sx={{ width: '100%', fontSize: '16px', textAlign: 'center'}}
                                onChange={(e) => setRoom(e.target.value)}
                                fullWidth
                                disabled={floor === ""}
                                >
                            {parseBuildingsToRooms(buildings,building || '',floor || '').map((room)=> (
                                <MenuItem key={room.id} value={room.name}>
                                    {room.name}
                                </MenuItem>
                            ))}
                            </TextField>
                            </Grid>
                        </Grid>
                        <Box sx={{width:'100%',height:'36px'}} position='relative'>
                            <Box position='absolute' sx={{left:0}}>
                                <Button sx={{ width: '80px', height: '36px', fontSize: '14px', minWidth:"unset"}} onClick={() => handleVisitCustomer(scheduleData.schedule.scheduleId)} >
                                    추후 할당
                                </Button>
                            </Box>
                            <Box position='absolute'sx={{right:0}}>
                                <Button sx={{ width: '60px', height: '36px', fontSize: '14px', minWidth:"unset",marginRight:'8px'}} onClick={() => handleVisitCustomer(scheduleData.schedule.scheduleId,building,floor,room)} >
                                    룸 할당
                                </Button>
                                <Button sx={{ width: '60px', height: '36px', fontSize: '14px', minWidth:"unset",backgroundColor: 'lightgray',color: 'black','&:hover': {backgroundColor: '#e08a8a',color: 'black'}}}
                                    onClick={handleCloseVisitModal}>
                                    취소
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                {scheduleData.schedule.status !== "cancel" && (
                    <Button sx={{ width: '100%', height: '24px', fontSize: '12px', textTransform: 'none', minWidth:"unset",backgroundColor: 'lightgray',color: 'black','&:hover': {backgroundColor: '#e08a8a',color: 'black'}}}
                        onClick={() => {if(window.confirm("고객님의 금일 예약을 취소하시겠습니까?")) {handleCancelSchedule(scheduleData.schedule.scheduleId)}}}>
                        취소
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}
export default ReservationCard  