import axios from 'axios';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

export interface Doctor {
    doctorUuid: string;
    name: string;
    phoneNumber : string;
    birthDate? : Date;
    joiningDate : Date;
    color : string;
    exitDate? : Date;
    imgUrl : string;
}
export const initialDoctor : Doctor ={
    doctorUuid : "",
    name : "",
    phoneNumber : "",
    joiningDate : new Date,
    color : "",
    imgUrl : ""
}

interface DoctorContextType {
  doctors: Doctor[];
  setDoctors: React.Dispatch<React.SetStateAction<Doctor[]>>;
  registerNewDoctor: (newDoctor: Doctor, img? : File) => Promise<string>;
  updateDoctorColor : (updateDoctorColor : Doctor) => void;
}

const DoctorsContext = createContext<DoctorContextType | undefined>(undefined);

export const DoctorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [doctors, setDoctors] = useState<Doctor[]>([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return;
        }
        const fetchDoctor = async () => {
        try {
            const fetchDoctorResponse = await axios.post('/api/v1/doctor/fetchDoctor',{},{headers:{Authorization: `Bearer ${token}`}});
            if (fetchDoctorResponse.status === 200) {
                const responseData: any[] = fetchDoctorResponse.data;
                const transformedData: Doctor[] = responseData.map((doctor) => ({
                    ...doctor,
                    uuid : doctor.doctorUuid,
                    birthDate: new Date(doctor.birthDate),
                    joiningDate: new Date(doctor.joiningDate),
                    exitDate : doctor.exitDate === null? null : new Date(doctor.exitDate),
                }));
                setDoctors(transformedData);
                console.log("fetching Doctors")
            } else{
                throw new Error('Failed to fetch doctor data');
            }
        } catch (error) {
            console.error('Error fetching doctor:', error);
        }
        };

        fetchDoctor();
    }, []);
    
    const updateDoctorColor = async (updatedDoctor: Doctor) => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return;
        }
        try {
            const updateDoctorColorResponse = await axios.post('/api/v1/doctor/updateDoctorColor',{doctorUuid : updatedDoctor.doctorUuid,color:updatedDoctor.color},{headers:{Authorization: `Bearer ${token}`}});
            if (updateDoctorColorResponse.status === 200) {
            // 서버에서 업데이트에 성공하면 상태 업데이트
            setDoctors((prevDoctors) =>
                prevDoctors.map((doctor) =>
                doctor.doctorUuid === updatedDoctor.doctorUuid ? updatedDoctor : doctor
            ));
            } else {
                throw new Error('Failed to update doctor data');
            }
        } catch (error) {
            console.error('Error update doctor:', error);
        }
    };

    const registerNewDoctor = async (newDoctor: Doctor,img? : File) => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return "fail";
        }
        try {
            const formData = new FormData();
            formData.append('doctorDto', new Blob([JSON.stringify(newDoctor)], { type: 'application/json' }));
            if (img) {
                formData.append('img', img);
            }
            const updateDoctorResponse = await axios.post('/api/v1/doctor/registerNewDoctor',formData,{headers:{Authorization: `Bearer ${token}`,}});
            if (updateDoctorResponse.status === 200) {
                const updatedDoctor = updateDoctorResponse.data
                setDoctors((prevDoctors) => {
                    const doctorExists = prevDoctors.some(doctor => doctor.doctorUuid === updatedDoctor.doctorUuid);
                    if (doctorExists) {
                        return prevDoctors.map(doctor =>
                        doctor.doctorUuid === updatedDoctor.doctorUuid ? updatedDoctor : doctor
                        );
                    } else {
                        return [...prevDoctors, updatedDoctor];
                    }
                });
                return updatedDoctor.doctorUuid;
            } else {
                throw new Error('Failed to update doctor data');
            }
        } catch (error) {
            console.error('Error update doctor:', error);
            return "fail"
        }
    };

  return (
    <DoctorsContext.Provider value={{ doctors, setDoctors, registerNewDoctor, updateDoctorColor}}>
      {children}
    </DoctorsContext.Provider>
  );
};

// Context를 사용하기 위한 커스텀 훅
export const useDoctor = () => {
  const context = useContext(DoctorsContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a DoctorProvider');
  }
  return context;
};