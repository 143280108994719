import React, { useState } from 'react';
import '../../App.css';
import Sidebar, {sidebarwidth} from '../Sidebar';
import { CssBaseline, Box, Grid, Typography} from '@mui/material';
import DoctorsList from './DoctorsList';
import DoctorWeeklySchedule from './DoctorWeeklySchedule';
import DoctorDetails from './DoctorDetails';
import { Doctor } from '../../context/DoctorsContext';
import RegisterDoctor from './RegisterDoctor';

export interface preDoctor{
  id : string;
  name : string;
  color : string;
}
export const doctors: preDoctor[] = [
  {id : "최두영", name:"최두영", color : '#edbbd4'},
  {id : "고범준", name:"고범준", color : '#33a9f8'},
  {id : "홍민표", name:"홍민표", color : '#e7dbbc'},
  {id : "김수현", name:"김수현", color : '#b2dab8'},
  {id : "전수환", name:"전수환", color : '#a8c7dc'},
  {id : "장태영", name:"장태영", color : '#1bd5c9'},
  {id : "이현규", name:"이현규", color : '#cbaae7'},
  {id : "이철희", name:"이철희", color : '#f5bdc0'},
  {id : "이정일", name:"이정일", color : '#615fc1'},
  {id : "이지혁", name:"이지혁", color : '#efcdbb'},
  {id : "김건일", name:"김건일", color : '#efcdbb'},
  {id : "박찬호", name:"박찬호", color : '#f8e03b'},
  {id : "이준수", name:"이준수", color : '#edbbd4'},
  {id : "안준영", name:"안준영", color : '#a643f5'},
  {id : "서민형", name:"서민형", color : '#b19a70'},
  {id : "none", name:"미지정", color : '#bdbdbd'},
]

export interface Counselor{
  id : string;
  name : string;
}
export const counselors: Counselor[] = [
  {id : "오민희", name:"오민희"},
  {id : "정슬기", name:"정슬기"},
  {id : "최미령", name:"최미령"},
  {id : "소효빈", name:"소효빈"},
  {id : "김지원", name:"김지원"},
  {id : "조윤정", name:"조윤정"},
  {id : "이아름", name:"이아름"},
  {id : "박소현", name:"박소현"},
  {id : "진우", name:"진우"},
  {id : "한아름", name:"한아름"},
  {id : "박연실", name:"박연실"},
  {id : "전정원", name:"전정원"},
  {id : "김정연", name:"김정연"},
  {id : "none", name:"미지정"},
]
export const doctorsColor : string[] =[
  "#f7a1c4",
  "#ffb3a7",
  "#ff9999",
  "#ffcc99",
  "#f5e6a2",
  "#b0e57c",
  "#7cd3a1",
  "#77c5d5",
  "#77a6f7",
  "#a677f7",
  "#db77f7",
  "#ff77ff",
  "#ff77a9",
  "#c6a9ed",
  "#f5a9bc",
  "#ffdd87",
  "#7cb3e5",
  "#7be5d3",
  "#e57cc6",
  "#e5c27c",
  "#b8e57c",
  "#e5997c",
  "#e57ca5",
  "#edbbd3"
]

const Doctors: React.FC = () => {

  const [selectedDoctor, setSelectedDoctor] = useState<Doctor>();
  const [isAddDoctor,setIsAddDoctor] = useState<boolean>(false);
  
  return (
    <Box sx={{ display: 'flex', position: 'relative',width:'100%',height:'100vh'}}>
      <CssBaseline />
      <Box sx={{ display: 'flex', position: 'sticky',left:0,zIndex:100, marginRight: '70px', backgroundColor: 'white' }}>
        <Sidebar />
      </Box>
      <Grid container height="100%" direction="column" padding='20px 20px 20px 0px'>
        <Grid container item xs={4} sx={{ height: '100%', overflow: 'hidden' }}>
          <Grid container item xs={12} sx={{ height: '100%', overflow: 'hidden' }}>
            <DoctorWeeklySchedule/>
          </Grid>
        </Grid>
        <Grid container item xs={8} sx={{paddingTop : '20px'}}>
          <Grid container item xs = {3.5} sx={{height:'100%'}}>
            <DoctorsList onDoctorSelect = {setSelectedDoctor} setIsAddDoctor={setIsAddDoctor}/>
          </Grid>
          <Grid container item xs = {8.5} sx={{height:'100%',paddingLeft:'20px'}}>
            {isAddDoctor? (
              <RegisterDoctor isAddDoctor = {isAddDoctor} setIsAddDoctor={setIsAddDoctor}/>
            ):(
              <DoctorDetails selectedDoctor={selectedDoctor}/>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Doctors;