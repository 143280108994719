import React from "react";
import { Drawer, List, ListItem, ListItemIcon, Tooltip } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useLocation } from "react-router-dom";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import Box from "@mui/material/Box";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { useAuth } from "../context/AuthContext";

export const sidebarwidth = 50;

const drawerSx = {
  flexShrink: 0,
  paddingTop: "20px", // 위쪽 여백 설정
  paddingBottom: "50px", // 아래쪽 여백 설정
  [`& .MuiDrawer-paper`]: {
    backgroundColor: "#655dc6", // 배경색 지정
    color: "#fff", // 텍스트 색상 지정
    width: sidebarwidth,
    height: "100vh",
    boxSizing: "border-box",
  },
};

const listItemSx = {
  display: "flex",
  justifyContent: "center",
  "&:hover": {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
  },
};

const listItemIconSx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "40px", // 아이콘 영역의 최소 너비 설정
  height: "40px",
};

// 현재 경로에 따라 아이콘 색상을 설정하는 함수
const getIconColor = (path: string, currentPath: string) => {
  return path === currentPath ? "#fff" : "#BDBDBD";
};

const Sidebar: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { user } = useAuth();
  return (
    <Drawer //Material-UI의 Drawer 컴포넌트 사용
      variant="permanent" //항상 화면에 고정되도록 설정
      anchor="left" //항상 왼쪽에 고정되도록 설정
      sx={drawerSx}
    >
      <List sx={{ paddingTop: "10px" }}>
        <Tooltip title="메인 페이지" placement="right">
          <ListItem component={Link} to="/home" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/home", currentPath) }} sx={listItemIconSx}>
              <HomeRoundedIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <Tooltip title="룸 화면" placement="right">
          <ListItem component={Link} to="/room" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/room", currentPath) }} sx={listItemIconSx}>
              <MeetingRoomRoundedIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <Tooltip title="스케쥴 차트" placement="right">
          <ListItem component={Link} to="/schedule" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/scheduletable", currentPath) }} sx={listItemIconSx}>
              <EventAvailableRoundedIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <Tooltip title="고객 관리" placement="right">
          <ListItem component={Link} to="/customers" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/customer", currentPath) }} sx={listItemIconSx}>
              <GroupsRoundedIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <Tooltip title="의사 정보" placement="right">
          <ListItem component={Link} to="/doctors" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/doctors", currentPath) }} sx={listItemIconSx}>
              <LocalHospitalRoundedIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <Tooltip title="장비 정보" placement="right">
          <ListItem component={Link} to="/devices" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/devices", currentPath) }} sx={listItemIconSx}>
              <ScreenSearchDesktopIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        <Tooltip title="시술 정보" placement="right">
          <ListItem component={Link} to="/surgerylist" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/surgerylist", currentPath) }} sx={listItemIconSx}>
              <FactCheckIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
        {(user?.authRole === "developer" || user?.authRole === "admin") && (
          <Tooltip title="직원 정보" placement="right">
            <ListItem component={Link} to="/employee" sx={listItemSx}>
              <ListItemIcon style={{ color: getIconColor("/employee", currentPath) }} sx={listItemIconSx}>
                <BadgeRoundedIcon sx={{ fontSize: 30 }} />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        )}
      </List>
      <Box sx={{ mt: "auto", display: "flex", flexDirection: "column", paddingBottom: "0px" }}>
        <Tooltip title="내 정보" placement="right">
          <ListItem component={Link} to="/account" sx={listItemSx}>
            <ListItemIcon style={{ color: getIconColor("/account", currentPath) }} sx={listItemIconSx}>
              <AccountCircleIcon sx={{ fontSize: 30 }} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
