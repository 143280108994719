import React, { Fragment } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import DeviceDetailsInfo from "./components/DetailsInfo";
import { useDevicesLocal } from "./context/DevicesLocalContext";

export interface DeviceDetailsProps {}

const DeviceDetails: React.FC<DeviceDetailsProps> = ({}) => {
  const { selectedDevice } = useDevicesLocal();

  return (
    <Box display={"flex"} height={"100%"}>
      <Grid container sx={{ flex: 1 }} direction={"column"}>
        <Grid item xs={12}>
          <Paper sx={{ height: "100%", boxShadow: 3, padding: 2, display: "flex", flexDirection: "column" }}>
            {selectedDevice ? (
              <Fragment>
                <Grid item height={"100%"}>
                  <DeviceDetailsInfo />
                </Grid>
                {/* 구분선 */}
                <Grid paddingY={1}>
                  <Divider />
                </Grid>

                <Grid item className="deviceChart" xs={12} height={"100%"}>
                  <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <Typography color={"grey"}>아직 사용하지 않는 공간입니다</Typography>
                  </Box>
                </Grid>
              </Fragment>
            ) : (
              <Grid container justifyContent={"center"} alignContent={"center"} height={"100%"}>
                <Grid item>
                  <Typography variant="h2">조회하고 싶은 장비를 목록에서 선택해 주세요</Typography>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceDetails;
