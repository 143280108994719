import { Box, FormControl, Grid, InputBase, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Device } from "../../../models/DevicesModel";
import { useDevicesLocal } from "../context/DevicesLocalContext";

export interface DeviceDetailsTableProps {
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  fixedData: { label: string; value: string | null }[];
  variableData: { location: string; area: string; userType: string; managingTeam: string; deviceStatus: string; memo: string };
  formValues: Device;
  setFormValues: React.Dispatch<React.SetStateAction<Device>>;
}

const DeviceDetailsTable: React.FC<DeviceDetailsTableProps> = ({ isEdit, fixedData, variableData, formValues, setFormValues }) => {
  const { selectedDevice } = useDevicesLocal();
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const managingTeamOptions = ["피부팀", "간호팀", "통증팀", "코디팀"];
  const deviceStatusOptions = ["사용가능", "보관중", "수리필요", "수리중"];
  const areaOptions = ["본관 4층", "본관 2층", "본관 G층", "신관 1층", "신관 2층", "창고"];
  const isLocationDisabled = formValues.area.includes("본관");

  // 폼 선택값 변경 핸들
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name: string; value: unknown }> | SelectChangeEvent<string | null>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value === "" ? null : value,
    }));
  };

  useEffect(() => {
    if (selectedDevice) {
      const area = selectedDevice.area;
      let initialLocation = selectedDevice.location;

      switch (area) {
        case "본관 4층":
        case "본관 2층":
          setLocationOptions(["메인홀"]);
          initialLocation = "메인홀";
          break;
        case "본관 G층":
          setLocationOptions(["복도"]);
          initialLocation = "복도";
          break;
        case "신관 1층":
          setLocationOptions(["1", "2", "3"]);
          break;
        case "신관 2층":
          setLocationOptions(["A", "M", "R", "E", "D", "복도"]);
          break;
        case "창고":
          setLocationOptions(["옥상", "지하"]);
          break;
        default:
          setLocationOptions([]);
          break;
      }

      // 폼 상태 초기화 시 location도 올바르게 설정
      setFormValues({ ...selectedDevice, location: initialLocation });
    }
  }, [selectedDevice]);

  const boxSx = { borderBottom: "1px solid lightgray", height: "25%", display: "flex", justifyContent: "center", alignItems: "center" };

  return (
    <Grid container item width={"100%"} flexDirection={"column"}>
      {/* 상위 컴포 */}
      <Grid container flex={5} flexDirection={"row"}>
        {/* 불변값 */}
        <Grid item flexDirection={"row"} xs={6}>
          <Box display={"flex"} sx={{ height: "100%", width: "100%", border: "1px solid lightgray" }}>
            <Box
              textAlign={"center"}
              display={"flex"}
              flex={3}
              flexDirection={"column"}
              sx={{ borderRight: "1px solid lightgray", backgroundColor: "#e1e1f5" }}
            >
              {fixedData.map((item, i) => {
                const isLastRow = i === fixedData.length - 1;
                return (
                  <Box sx={{ ...boxSx, border: isLastRow ? 0 : undefined }} key={i}>
                    <Typography fontSize={13}>{item.label ?? "-"}</Typography>
                  </Box>
                );
              })}
            </Box>
            <Box textAlign={"center"} display={"flex"} flex={5} flexDirection={"column"}>
              {fixedData.map((item, i) => {
                const isLastRow = i === fixedData.length - 1;
                return (
                  <Box sx={{ ...boxSx, border: isLastRow ? 0 : undefined, backgroundColor: isEdit ? "#f1f1f1" : undefined }} key={i}>
                    <Typography fontSize={13}>{item.value ?? "-"}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Grid>
        {/* 유동값 */}
        <Grid item flexDirection={"row"} xs={6}>
          <Box display={"flex"} sx={{ height: "100%", width: "100%", border: "1px solid lightgray", borderLeft: 0 }}>
            <Box
              textAlign={"center"}
              display={"flex"}
              flex={3}
              flexDirection={"column"}
              sx={{ borderRight: "1px solid lightgray", backgroundColor: "#e1e1f5" }}
            >
              <Box sx={boxSx}>
                <Typography fontSize={13}>위치</Typography>
              </Box>
              <Box sx={boxSx}>
                <Typography fontSize={13}>사용현황</Typography>
              </Box>
              <Box sx={boxSx}>
                <Typography fontSize={13}>담당팀</Typography>
              </Box>
              <Box sx={{ ...boxSx, border: 0 }}>
                <Typography fontSize={13}>사용자 구분</Typography>
              </Box>
            </Box>
            <Box textAlign={"center"} display={"flex"} flex={5} flexDirection={"column"}>
              <Box sx={boxSx}>
                {isEdit ? (
                  <Box display={"flex"} flexDirection={"column"} gap={0.5} padding={1}>
                    <Box display="flex" alignItems="center">
                      <InputLabel sx={{ marginRight: "10px", fontSize: "13px", width: "30px" }}>건물</InputLabel>
                      <FormControl size="small" required>
                        <Select
                          id="area-select"
                          name={"area"}
                          value={formValues!.area}
                          onChange={handleChange}
                          fullWidth
                          sx={{ height: "18px", fontSize: "13px", padding: 0, minWidth: "120px" }}
                        >
                          {areaOptions.map((option, i) => (
                            <MenuItem key={i} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <InputLabel sx={{ marginRight: "10px", fontSize: "13px", width: "30px" }}>층</InputLabel>
                      <FormControl size="small" required>
                        <Select
                          id="floor-select"
                          name="location"
                          value={formValues.location}
                          onChange={handleChange}
                          fullWidth
                          disabled={isLocationDisabled}
                          sx={{ height: "18px", fontSize: "13px", padding: 0, minWidth: "120px" }}
                        >
                          {locationOptions.map((option, i) => (
                            <MenuItem key={i} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                ) : (
                  <Typography fontSize={13}>
                    {variableData.area} / {variableData.location}
                  </Typography>
                )}
              </Box>
              <Box sx={boxSx}>
                {isEdit ? (
                  <FormControl size="small" required>
                    <Select
                      id="deviceStatus-select"
                      name={"deviceStatus"}
                      value={formValues!.deviceStatus}
                      onChange={handleChange}
                      fullWidth
                      sx={{ height: "20px", fontSize: "13px", padding: 0, minWidth: "120px" }}
                    >
                      {deviceStatusOptions.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography fontSize={13}>{variableData.deviceStatus}</Typography>
                )}
              </Box>
              <Box sx={boxSx}>
                {isEdit ? (
                  <FormControl size="small" required>
                    <Select
                      id="managingTeam-select"
                      name={"managingTeam"}
                      value={formValues?.managingTeam === null ? "" : formValues?.managingTeam}
                      onChange={handleChange}
                      fullWidth
                      sx={{ height: "20px", fontSize: "13px", padding: 0, minWidth: "120px" }}
                    >
                      <MenuItem value="">선택안함</MenuItem>
                      {managingTeamOptions.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography fontSize={13}>{selectedDevice!.managingTeam ?? "-"}</Typography>
                )}
              </Box>
              <Box sx={{ ...boxSx, border: 0 }}>
                {isEdit ? (
                  <InputBase
                    size="small"
                    value={formValues?.userType === null ? "" : formValues?.userType}
                    name="userType"
                    onChange={handleChange}
                    sx={{
                      width: "90%",
                      border: "none",
                      backgroundColor: "#fafafa",
                      outline: "none",
                      textAlign: "center",
                      padding: "5px",
                    }}
                    inputProps={{
                      style: {
                        padding: "0 3px",
                        fontSize: "12px",
                      },
                    }}
                  />
                ) : (
                  <Typography fontSize={13}>{selectedDevice!.userType ?? "-"}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* 하위 컴포 */}
      <Grid container flex={3}>
        <Grid container item xs={6} sx={{ border: "1px solid lightgray", borderTop: 0 }}>
          <Box
            display={"flex"}
            flex={3}
            textAlign={"center"}
            sx={{
              backgroundColor: "#e1e1f5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "1px solid lightgray",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography fontSize={13}>
                AS 회사/
                <br />
                연락처
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flex={5}
            textAlign={"center"}
            sx={{ justifyContent: "center", alignItems: "center", backgroundColor: isEdit ? "#f1f1f1" : undefined }}
          >
            <Box sx={{ display: "flex", padding: 1 }}>
              <Typography fontSize={12}>{selectedDevice!.asContact ?? "-"}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid container item xs={6} sx={{ border: "1px solid lightgray", borderTop: 0, borderLeft: 0 }}>
          <Box
            display={"flex"}
            flex={3}
            textAlign={"center"}
            sx={{
              backgroundColor: "#e1e1f5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "1px solid lightgray",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography fontSize={13}>비고</Typography>
            </Box>
          </Box>
          <Box display={"flex"} flex={5} textAlign={"center"} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isEdit ? (
                <InputBase
                  size="small"
                  multiline
                  rows={3}
                  value={formValues?.memo === null ? "" : formValues?.memo}
                  name="memo"
                  onChange={handleChange}
                  sx={{
                    width: "90%",
                    border: "none",
                    backgroundColor: "#fafafa",
                    outline: "none",
                    textAlign: "center",
                    padding: "5px",
                  }}
                  inputProps={{
                    style: {
                      padding: "0 3px",
                      fontSize: "12px",
                    },
                  }}
                />
              ) : (
                <Box sx={{ display: "flex", padding: 1 }}>
                  <Typography fontSize={13}>{selectedDevice!.memo ?? "-"}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DeviceDetailsTable;
