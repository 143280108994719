import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDevice } from "../../context/DevicesContext";
import { Device } from "../../models/DevicesModel";
import { useDevicesLocal } from "./context/DevicesLocalContext";

export interface DevicesListProps {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const headerRows: { [key: string]: string } = {
  area: "위치",
  title: "호칭",
  usageStatus: "사용현황",
};

const DevicesList: React.FC<DevicesListProps> = ({ openForm, setOpenForm }) => {
  const [openGroup, setOpenGroup] = useState<{ [key: string]: boolean }>({});
  const { filteredDevices, selectedDevice, setSelectedDevice, searchQuery, isEdit, setIsEdit } = useDevicesLocal();

  // 선택된 장비 상세 정보
  const handleDeviceRowClick = (item: Device | null) => {
    if (isEdit) {
      const userConfirmed = window.confirm("정보 수정 페이지를 벗어나시겠습니까?");
      if (userConfirmed) {
        setIsEdit(false);
        item === selectedDevice ? setSelectedDevice(null) : setSelectedDevice(item);
      }
    } else if (openForm) {
      const userConfirmed = window.confirm("정보 생성 페이지를 벗어나시겠습니까?");
      if (userConfirmed) {
        setOpenForm(false);
        item === selectedDevice ? setSelectedDevice(null) : setSelectedDevice(item);
      }
    }
    item === selectedDevice ? setSelectedDevice(null) : setSelectedDevice(item);
  };

  // 데이터 그룹화
  const handleGroupClick = (area: string) => {
    setOpenGroup((prev) => ({ ...prev, [area]: !prev[area] }));
  };

  const groupedData = filteredDevices.reduce((acc, item) => {
    const areaKey = item.area;
    if (!acc[areaKey]) {
      acc[areaKey] = [];
    }
    acc[areaKey].push(item);
    return acc;
  }, {} as { [key: string]: typeof filteredDevices });

  // view
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
      <Table size="small" stickyHeader aria-label="devices table">
        <TableHead>
          <TableRow>
            {Object.values(headerRows).map((header, index) => (
              <TableCell key={index} sx={{ backgroundColor: "#e1e1f5", textAlign: "center", width: header === "호칭" ? "50%" : "25%" }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {filteredDevices.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: "center", color: "grey" }}>검색 결과가 없습니다</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {Object.keys(groupedData).map((building, i) => (
              <React.Fragment key={building}>
                {/* 건물 */}
                <TableRow key={i} hover onClick={() => handleGroupClick(building)} sx={{ cursor: "pointer", backgroundColor: "#F9F9FD" }}>
                  <TableCell colSpan={4}>
                    <IconButton>
                      <ExpandMoreIcon
                        style={{
                          transform: openGroup[building] || searchQuery ? "rotate(180deg)" : "rotate(0deg)",
                          transition: "0.3s",
                        }}
                      />
                    </IconButton>
                    <span style={{ color: "black" }}>{building}</span>
                    <span style={{ color: "gray" }}> ({groupedData[building].length}개)</span>
                  </TableCell>
                </TableRow>
                {/* 층 collapse */}
                <TableRow>
                  <TableCell colSpan={4} sx={{ padding: 0 }}>
                    <Collapse in={openGroup[building] || Boolean(searchQuery)} timeout="auto" unmountOnExit>
                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          {groupedData[building].map((item, i) => (
                            <TableRow
                              key={i}
                              hover
                              sx={{ cursor: "pointer", backgroundColor: selectedDevice === item ? "#f0f0f0" : "inherit" }}
                              onClick={() => handleDeviceRowClick(item)}
                            >
                              <TableCell sx={{ textAlign: "center", width: "25%" }}>{item.location}</TableCell>
                              <TableCell sx={{ textAlign: "center", width: "50%" }}>{item.title}</TableCell>
                              <TableCell
                                sx={{
                                  width: "25%",
                                  textAlign: "center",
                                  backgroundColor: item.deviceStatus?.includes("사용") ? "lightgreen" : "pink",
                                }}
                              >
                                {item.deviceStatus}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default DevicesList;
