import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { Device } from "../../../models/DevicesModel";
import { useDevice } from "../../../context/DevicesContext";

interface DevicesLocalContextType {
  selectedDevice: Device | null;
  setSelectedDevice: (device: Device | null) => void;
  handleSelectDevice: (device: Device) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  filteredDevices: Device[];
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const DevicesLocalContext = createContext<DevicesLocalContextType | undefined>(undefined);

// 빈 문자열을 null로 변환
const replaceEmptyWithNull = <T extends Record<string, any>>(obj: T): T => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === "" ? null : value])) as T;
};

export const DevicesLocalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { deviceList } = useDevice();
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]); // 초기 상태로 빈 배열 설정

  // 장비 리스트가 변경될 때마다 필터링 업데이트
  useEffect(() => {
    const filtered = deviceList.filter((device) => {
      return device.title?.includes(searchQuery) || device.area?.includes(searchQuery);
    });
    setFilteredDevices(filtered); // 업데이트된 리스트 설정
  }, [deviceList, searchQuery]); // deviceList와 searchQuery가 변경될 때마다 실행

  const handleSelectDevice = (device: Device) => {
    setSelectedDevice(replaceEmptyWithNull(device));
  };

  return (
    <DevicesLocalContext.Provider
      value={{
        selectedDevice,
        setSelectedDevice,
        handleSelectDevice,
        searchQuery,
        setSearchQuery,
        filteredDevices,
        isEdit,
        setIsEdit,
      }}
    >
      {children}
    </DevicesLocalContext.Provider>
  );
};

export const useDevicesLocal = () => {
  const context = useContext(DevicesLocalContext);
  if (!context) {
    throw new Error("useDevicesLocal must be used within a DevicesLocalProvider");
  }
  return context;
};
