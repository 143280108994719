import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { CssBaseline,Grid, Paper, Typography, Box, IconButton, Checkbox} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LocalizationProvider,DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, format, set } from 'date-fns';
import { sidebarwidth } from '../Sidebar';
import { Building,buildings } from '../../models/RoomModel';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import { ko } from 'date-fns/locale';
import 'dayjs/locale/ko';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import {doctors} from '../Doctors/Doctors';
import { useQuery } from '@tanstack/react-query';
import { ScheduleData,Customer,Schedule,Service } from '../../models/HomeModel';

//Date -> String 변환
export const formatDate = (date: Date | null): string => {
  if (!date) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
//방 배열 정리
const parseBuildingsToFloors = (buildings: Building[]) => {
    const floors = buildings.flatMap(building =>
      building.floors.map(floor => ({
        id : building.id + " "+floor.id,
        floor: building.name+" "+floor.name,
        rooms: floor.rooms.map(room => ({
          id : room.id,
          name : room.name
        }
        )),
      }))
    );
    return floors;
  };
const floorsArray = parseBuildingsToFloors(buildings); //{id : 본관 4층, floor : , rooms : {id : , name : }}
const roomsArray : string[] = floorsArray.flatMap(floorsArray =>floorsArray.rooms.map(room =>room.name)) //[4A, 4M, ...]

export const initialCustomer : Customer= {
  customerUuid : '',
  customerId : '',
  vegasId : '',
  name : '',
  gender : '',
  birthDate : '',
  phoneNumber : '',
  address : '',
  counselor : '',
  doctor : '',
  memo : '',
  age : '',
  country : '대한민국',
}
export const initialSchedule : Schedule={
  scheduleId : '',
  customerUuid : '',
  scheduleUuid : '',
  visitTime:'',
  date : new Date(),
  building : '',
  floor : '',
  room : '',
  program : '',
  counselor : 'none',
  doctor : 'none',
  expectedVisitTime : '',
  expectedReturnTime : '',
  status : '',
  surgeriesPlan : '',
  vegasReservationMemo : '',
  vegasEmployeeMemo : '',
  arrangerMemo : '',
  counselorMemo : '',
  doctorMemo : '',
  roomHistory:[]
}
export const initialService : Service = {
  serviceId : '',
  serviceUuid:'',
  scheduleUuid : '',
  name : '',
  doctor : 'none',
  startTime : '',
  endTime : '',
  serviceTime : '',
  room : '',
  memo : '',
  status : 'beforeReady',
}
export const initialScheduleData : ScheduleData = {
  customer : initialCustomer,
  schedule : initialSchedule,
  service : [],
}



//CalendarTable 컴포넌트 시작
const CalendarTable: React.FC = () => {
  const topBoxRef = useRef<HTMLDivElement>(null);
  const [topOffset, setTopOffset] = useState(0);
  useEffect(() => {
    if (topBoxRef.current) {
      setTopOffset(topBoxRef.current.offsetHeight); // 상단 Box의 높이를 가져와 상태로 설정
    }
  }, []);

  //고객 일정 추가 모달 오픈,닫기
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const handleOpenScheduleModal = () => {setScheduleModalOpen(true);};
  const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
  };

  //날짜 선택
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const popperAnchorRef = useRef(null);
  const handlePrevDay = () => {setSelectedDate((prevDate) => addDays(prevDate, -1));};
  const handleNextDay = () => {setSelectedDate((prevDate) => addDays(prevDate, 1));};
  const handleDateChange = (date :Date) => {setSelectedDate(date); toggleDatePicker();};
  const toggleDatePicker = () => {setDatePickerOpen(!isDatePickerOpen);};
  const dayMap: { [key: string]: string } = {
    'Mon': '월',
    'Tue': '화',
    'Wed': '수',
    'Thu': '목',
    'Fri': '금',
    'Sat': '토',
    'Sun': '일'
  };

  //일정 불러오기

  const [scheduleItems, setScheduleItems] = useState<ScheduleData[]>([]);
  const { data: scheduleData, error, isLoading} = useQuery({
    queryKey: ['scheduleData', formatDate(selectedDate)], // 쿼리 키
    queryFn: () => fetchSchedule(), // 데이터를 가져오는 함수
    staleTime: 5000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false, // 창 포커스 시 재요청 방지
    retry: 3, // 쿼리 실패 시 3번까지 자동으로 재시도
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // 재시도 딜레이 설정 (점진적 증가)
  });
  useEffect(() => {
    if (scheduleData) {
      setScheduleItems(scheduleData);
    }
  }, [scheduleData]);

  const fetchSchedule = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }
    try {
      const scheduleResponse = await axios.post('/schedule/fetchSchedule', { date : formatDate(selectedDate) },{headers:{Authorization: `Bearer ${token}`}});
      console.log(scheduleResponse.data)
        if (scheduleResponse.status === 200) {
          const parsedScheduleResponse : ScheduleData[]=(scheduleResponse.data as any[]).map((item:any) =>{
            return {
              customer : item.customer,
              schedule : item.schedule,
              service: [
                ...item.service.filter((service : Service) => service.name === '방문'),
                ...item.service.filter((service : Service) => service.name !== '방문'),
              ]
            }
          });
          setScheduleItems(parsedScheduleResponse);
          return parsedScheduleResponse;
        } else {
            console.error('Failed to fetch schedule info');
        }
    } catch (error) {
        console.error('Error fetching schedule info', error);
    }
  };
  useEffect(() => {fetchSchedule()}, [selectedDate]);
  
  
  //윈도우 크기 설정
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // 윈도우 크기 조정 이벤트 리스너 추가
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // 클린업 함수로 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const timesArray = useMemo(() => {      //시간 설정
    return Array.from({ length: 60 }, (_, i) => {
      const hours = 9 + Math.floor((i + 2) / 4);
      const minutes = ((i + 2) % 4) * 15;
      return `${hours}:${minutes === 0 ? '00' : minutes}`;
    });
  }, []);
  
  //엑셀 업로드
  const [uploadResult, setUploadResult] = useState<string | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      handleFileUpload(file);
    }
  };
  const handleFileUpload = async (file: File) => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post('/schedule/uploadExcel', formData, {headers: {Authorization: `Bearer ${token}`,'Content-Type': 'multipart/form-data',},});

      if (response.status === 200) {
        const jsonResult = response.data;
        console.log(jsonResult);
        setUploadResult(JSON.stringify(jsonResult, null, 2));
      } else {
        setUploadResult('Error uploading file');
      }
    } catch (error) {
      console.error('Error:', error);
      setUploadResult('An error occurred');
    }
  };
  //원장님 시술 펴기
  const [isDoctorSchedule, setIsDoctorSchedule] = useState(false);
  
  return (
    <Box sx={{  marginBottom:'20px', position:'relative', background:'white'}}>
      <Box ref={topBoxRef} sx={{ width: `calc(${windowWidth}px - ${sidebarwidth+20}px)`,position: 'sticky', top: 0, left:`${sidebarwidth+20}px`, zIndex: 10, backgroundColor: 'white', paddingTop:'20px',paddingRight:'20px'}}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
          <Box sx={{ position: 'absolute', right: 0,display: 'flex', flexWrap: 'wrap', width: '30%',gap: '2px',justifyContent: 'flex-end' }}>
          {doctors.map((doctor) => (
            <Box 
              key={doctor.id} 
              sx={{ display: 'flex', alignItems: 'center'}}
            >
              {/* Color box */}
              <Box 
                sx={{ 
                  width: '10px', 
                  height: '10px', 
                  backgroundColor: doctor.color, 
                  marginRight: '4px' 
                }} 
              />
              {/* Doctor name */}
              <Typography sx={{fontSize:'10px'}}>{doctor.name}</Typography>
            </Box>
          ))}
          </Box>
          <IconButton onClick={handlePrevDay}>
            <ArrowBackIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
            <Box>
              <Typography align="center" onClick = {toggleDatePicker} sx={{fontSize:24}} ref={popperAnchorRef}>
                  {selectedDate ? format(selectedDate, 'yyyy년 MM월 dd일'):''} {selectedDate ? `(${dayMap[format(selectedDate,"EEE")]})` : ''}
              </Typography>
              {isDatePickerOpen && (        
                <Box>
                  <DatePicker
                    open={isDatePickerOpen}
                    onClose={toggleDatePicker}
                    onChange={(date: Date | null) => {
                      if (date !== null) {handleDateChange(date);}
                    }}
                    value={selectedDate}
                    slots={{ textField: (props) => <></>}}
                    slotProps={{
                      popper: {
                        anchorEl: popperAnchorRef.current,
                        placement: 'bottom-start'},
                      day: {
                          sx: {
                            '&:hover': {backgroundColor: '#e1e1f5 !important'},
                            '&.Mui-selected': {
                              backgroundColor: '#655dc6 !important',
                              color: 'white'
                            },
                            '&.Mui-selected:hover': {backgroundColor: '#554ea2 !important'},
                          },
                        },
                  }}/>
                </Box>
              )}
            </Box>
          </LocalizationProvider>
          <IconButton onClick={handleNextDay}>
            <ArrowForwardIcon sx={{fontSize:20}}/>
          </IconButton>
          <Box sx={{ position: 'absolute', left: 0,display: 'flex', flexWrap: 'wrap' }}>
            <input
              accept=".xlsx, .xls"
              style={{ display: 'none' }}
              id="upload-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-button-file">
              <IconButton component="span">
                <CloudUploadRoundedIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </label>
          </Box>
          <Box sx={{ position: 'absolute', left: 40,display: 'flex', flexWrap: 'wrap' }}>
          <Checkbox 
            size='small'
            sx={{padding:'0px 2px 0px 0px'}}
            checked={isDoctorSchedule} 
            onChange={(event) => setIsDoctorSchedule(event.target.checked)} 
            />
            <Typography sx={{fontSize:"14px" }}>시술 자세히보기</Typography>
            
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${roomsArray.length+1}, 80px)`,
        gridTemplateRows: `30px 24px ,repeat(${timesArray.length}, 100px)`, 
        position: 'sticky',
        paddingRight:'20px',
      }}>
        <Box sx={{background:'#f0f0f0', textAlign:'center',fontWeight:'bold',border : '1px solid #ddd',zIndex:51,top: `${topOffset}px`,left:`${sidebarwidth+20}px`,position:'sticky', gridColumn:'1/2',gridRow:'1/3',}}>
          <IconButton onClick={handleOpenScheduleModal}>
            <PersonAddAlt1RoundedIcon sx={{fontSize:30}}/>
          </IconButton>
        </Box>
        {floorsArray.map((floor) => (
        <Grid item key={floor.id} sx={{ gridColumn: `span ${floor.rooms.length}`, gridRow:1,background:'#f0f0f0', textAlign:'center',border : '1px solid #ddd',display:'flex',alignItems: 'center', justifyContent:'center',zIndex:50,top: `${topOffset}px`,position:'sticky'}}>
          <Typography sx={{fontSize:"20px",fontWeight:'bold', }}>{floor.floor}</Typography>
        </Grid>))}
        {floorsArray.map((floor) =>
          floor.rooms.map((room) => (
            <Grid item key={room.id}  sx={{gridRow:'2/3',background:'#f0f0f0', textAlign:'center',border : '1px solid #ddd',display:'flex',alignItems: 'center', justifyContent:'center',zIndex:50,top: `${topOffset+30}px`,position:'sticky'}}>
              <Typography sx={{fontSize:"13px"}}>{room.name}</Typography>
            </Grid>)))}
        {timesArray.map((time) => (
          <React.Fragment key={time}>
          <Grid item key ={time} sx={{gridColumn:1, background: '#f0f0f0',textAlign: 'center',border: '1px solid #ddd',position:'sticky',left:`${sidebarwidth+20}px`,zIndex:50}}>{time}</Grid>  
          {roomsArray.map((room) => (
            <Grid key={`${time}-${room}`} sx={{border: '1px solid #ddd',minHeight:'100px'}}></Grid>
          ))}
          </React.Fragment>
        ))}
        
      </Box>
    </Box>


  );
}

export default CalendarTable;