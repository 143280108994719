import React, { useState, useEffect } from "react";
import { Card, Box, CardContent, Typography, Button, Grid, useTheme, TextField, skeletonClasses } from "@mui/material";
import Sidebar, { sidebarwidth } from "../Sidebar";
import { useAuth } from "../../context/AuthContext";
import { accountController } from "../../controllers/AccountController";

const Account: React.FC = () => {
  const { logout, user, setUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();

  // 비밀번호 로직
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isSamePassword, setIsSamePassword] = useState<boolean>(true);
  useEffect (() => {
    if (password !== rePassword) {
      setError("비밀번호가 일치하지 않습니다");
      setIsSamePassword(false);
    } else {
      setError("");
      setIsSamePassword(true);
    }
  },[password,rePassword])

  interface Input {
    userUuid?: string;
    phoneNumber: string;
    mail: string;
    introduction: string;
    password?: string;
  }

  // user 정보가 변경될 때 input 상태를 업데이트
  useEffect(() => {
    setInput({
      mail: user?.mail ?? "",
      phoneNumber: user?.phoneNumber ?? "",
      introduction: user?.introduction ?? "",
    });
  }, [user]);

  // 유저 인풋
  const [input, setInput] = useState<Input>({
    mail: user?.mail ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    introduction: user?.introduction ?? "",
  });

  // 만약에 유저 없으면 로딩
  if (!user) {
    return <div className="App-header">Loading...</div>;
  }

  // 핸들러 : 정보 수정 클릭 버튼
  const handleEditClick = async () => {
    // 수정 중
    setIsEditing(true);
  };

  // 핸들러 : 정보 수정 취소 버튼
  const handleEditCancelClick = () => {
    setInput({ mail: user?.mail, phoneNumber: user?.phoneNumber, introduction: user?.introduction });
    setPassword("");
    setRePassword("");
    setError("");
    console.log(password);
    setIsEditing(false);
  };
  // 핸들러 : 정보 수정 인풋 수정
  const handleEditInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // 특정 name 필드에만 숫자 입력을 제한 (예: 'age' 필드)
    if (name === "phoneNumber") {
      // 숫자만 허용하는 정규식
      setInput((prev) => ({
        ...prev,
        [name]: value.replace(/\D/g, ""), // 숫자가 아닌 모든 문자를 제거
      }));
    } else {
      // 숫자 필드가 아닌 다른 필드들은 그대로 처리
      setInput((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // 핸들러 : 정보 수정 완료 버튼
  const handleEidtSaveClick = async () => {
    // 비밀번호
    if (password !== "") {
      input.password = password;
    }
    // uuid 추가
    input.userUuid = user.userUuid;
    // api 통신
    const newAccountInfo = await accountController.handleAccountInfoSaveClick(user, input);
    setUser({
      ...user,
      phoneNumber: newAccountInfo.phoneNumber,
      mail: newAccountInfo.mail,
      introduction: newAccountInfo.introduction,
    });
    setPassword("");
    setRePassword("");
    setIsEditing(false);
  };

  // 비밀번호 확인 변경 시 에러 확인
  const handleRePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRePassword(value);
    // 비밀번호가 일치하는지 확인
    if (value !== password) {
      setError("비밀번호가 일치하지 않습니다");
      setIsSamePassword(false);
    } else {
      setError("");
      setIsSamePassword(true);
    }
    
  };

  return (
    <Box sx={{ display: "flex", marginLeft: sidebarwidth + "px", height: "100vh", verticalAlign: "center" }}>
      <Sidebar />
      <Grid container justifyContent="center" alignItems="center">
        <Card sx={{ width: 640, height: 650 }}>
          <CardContent>
            {/* FIXME: Header  */}
            <Grid container gap={2} direction={"column"} justifyContent={"center"}>
              <Grid item sx={{ width: "100%" }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} height="60px">
                  <Box>
                    <Typography variant="h2">{user.name}</Typography>
                    <Typography variant="h6">{user.team}</Typography>
                  </Box>
                  <Box display="flex" flexDirection={"column"} alignItems="center">
                    {isEditing ? null : (
                      <Button
                        onClick={handleEditClick}
                        sx={{ height: "24px", fontSize: "12px", textTransform: "none", minWidth: "70px", mb: "9px" }}
                      >
                        정보 수정
                      </Button>
                    )}
                    <Button
                      onClick={logout}
                      sx={{
                        height: "24px",
                        fontSize: "12px",
                        textTransform: "none",
                        minWidth: "70px",
                        backgroundColor: "white",
                        color: "grey",
                        "&:hover": { backgroundColor: "white", color: "red" },
                      }}
                    >
                      로그아웃
                    </Button>
                  </Box>
                </Box>
              </Grid>

              {/* FIXME: account */}
              <Grid item borderRadius="4px" sx={{ width: "100%", border: "1px solid #e0e0ff" }}>
                <Box display="flex" flexDirection={"column"} alignItems="start">
                  <Box
                    padding={1}
                    display="flex"
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                    sx={{ borderBottom: "1px solid #e0e0ff", boxSizing: "border-box" }}
                  >
                    <Typography variant="h3">Account</Typography>
                    <Typography fontSize={12} color={"lightgray"}>
                      * 비밀번호 미입력 시 변경되지 않습니다
                    </Typography>
                  </Box>
                  <Box padding={2} display={"flex"} flexDirection={"column"} gap={1}>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width={"80px"}>
                        아이디
                      </Typography>
                      <Typography variant="body2">{user.loginId}</Typography>
                    </Box>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width="80px">
                        비밀번호
                      </Typography>
                      {isEditing ? (
                        <>
                          <TextField
                            name="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            size="small"
                            placeholder="새로운 비밀번호"
                            sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                            error={!!error}
                          />
                          <TextField
                            name="repassword"
                            type="password"
                            value={rePassword}
                            onChange={handleRePasswordChange}
                            size="small"
                            placeholder="새로운 비밀번호 재입력"
                            sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                            error={!!error}
                          />
                          {error && (
                            <Typography variant="body2" color="error" sx={{ marginLeft: 1, fontSize: "12px" }}>
                              {error}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography variant="body2">⦁⦁⦁⦁⦁⦁⦁</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* FIXME: Contact  */}
              <Grid item borderRadius="4px" sx={{ width: "100%", border: "1px solid #e0e0ff" }}>
                <Box display="flex" flexDirection={"column"} alignItems="start">
                  <Box padding={1} width={"100%"} sx={{ borderBottom: "1px solid #e0e0ff", boxSizing: "border-box" }}>
                    <Typography variant="h3">Contact</Typography>
                  </Box>
                  <Box padding={2} display={"flex"} flexDirection={"column"} gap={1}>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width={"80px"}>
                        전화번호
                      </Typography>
                      {isEditing ? (
                        <TextField
                          name="phoneNumber"
                          value={input.phoneNumber}
                          placeholder="전화번호 입력"
                          onChange={handleEditInput}
                          size="small"
                          sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                        />
                      ) : (
                        <Typography variant="body2">{user.phoneNumber === "" ? "-" : user.phoneNumber}</Typography>
                      )}{" "}
                    </Box>
                    <Box display="flex" flexDirection={"row"} alignItems="center" gap={1} height={"30px"}>
                      <Typography variant="body2" color={`${theme.palette.primary.main}`} width="80px">
                        이메일
                      </Typography>
                      {isEditing ? (
                        <TextField
                          name="mail"
                          value={input.mail}
                          onChange={handleEditInput}
                          placeholder="메일 입력"
                          size="small"
                          sx={{ "& .MuiInputBase-root": { height: "25px", fontSize: "12px" } }}
                        />
                      ) : (
                        <Typography variant="body2">{user.mail === "" ? "-" : user.mail}</Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              {/* FIXME: introduction */}
              <Grid item borderRadius="4px" sx={{ width: "100%", border: "1px solid #e0e0ff" }}>
                <Box display="flex" flexDirection={"column"} alignItems="start">
                  <Box padding={1} width={"100%"} sx={{ borderBottom: "1px solid #e0e0ff", boxSizing: "border-box" }}>
                    <Typography variant="h3">Introduction</Typography>
                  </Box>
                  <Box
                    padding={2}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={5}
                    height={"110px"}
                    width={"100%"}
                    sx={{ boxSizing: "border-box" }}
                  >
                    {isEditing ? (
                      <TextField
                        value={input.introduction}
                        name="introduction"
                        variant="outlined"
                        multiline
                        disabled={!isEditing}
                        rows={2}
                        placeholder="자기소개를 입력하세요"
                        fullWidth
                        onChange={handleEditInput}
                        sx={{ "& .MuiInputBase-root": { fontSize: "12px" } }}
                      />
                    ) : (
                      <Typography variant="body2" sx={{ height: 3 * 24 }}>
                        {user.introduction === "" ? "자기소개가 없습니다." : user.introduction}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Box>
                {isEditing ? (
                  <Grid container alignItems="center">
                    <Grid item xs={12} sx={{ textAlign: "center", marginTop: "10px" }}>
                      <Button
                        onClick={handleEidtSaveClick} disabled={!isSamePassword}
                        sx={{ height: "24px", fontSize: "12px", textTransform: "none", minWidth: "70px", mr: "7px",":disabled":{background:'lightgray'} }}
                      >
                        수정 완료
                      </Button>
                      <Button
                        onClick={handleEditCancelClick}
                        sx={{
                          height: "24px",
                          fontSize: "12px",
                          textTransform: "none",
                          minWidth: "70px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        취소
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default Account;
