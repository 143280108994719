import React from "react";
import { Grid, Typography, Button,Box, Tooltip} from "@mui/material";
import { CardProp } from "../DailyOperations";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { ScheduleController } from "../../../controllers/HomeController";


const VisitingCard : React.FC<CardProp> = ({scheduleData,setScheduleDataList}) => {
    const handleCustomerWait = async (scheduleId: string) => {
        const updatedSchedule = await ScheduleController.customerWait(scheduleId);
        setScheduleDataList(prevData =>
            prevData.map(item =>
              item.schedule.scheduleId === updatedSchedule.scheduleId
                ? { ...item, schedule: { ...item.schedule, ...updatedSchedule } }
                : item
            )
          );
    };
    const handleCustomerReturn = async (scheduleId: string) => {
        const updatedSchedule = await ScheduleController.customerReturn(scheduleId);
        setScheduleDataList(prevData =>
            prevData.map(item =>
              item.schedule.scheduleId === updatedSchedule.scheduleId
                ? { ...item, schedule: { ...item.schedule, ...updatedSchedule } }
                : item
            )
          );
    };
    

    return (
        <Grid container item sx={{width:'100%',height:'88px',background:'#fafafa', borderBottom:'1px solid #b0b0b0',padding:'8px'}}>
            <Grid container item sx={{width:'100%',height:'54px'}}>
                <Grid container item xs={9.5}>
                    <Box width='100%' display='flex' overflow='hidden' position="relative">
                        <Tooltip title={scheduleData.customer.name} arrow followCursor placement="right">
                            <Typography sx={{color:'#655dc6',fontWeight:'bold',width: 'auto',height: '18px',fontSize: '12px',maxWidth: '70%',overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis'}}>
                                {scheduleData.customer.name}
                            </Typography>
                        </Tooltip>
                        <Typography sx={{width:'20%',height:'18px',fontSize:'12px',maxWidth:'20%'}}>
                            ({scheduleData.customer.gender === "M"? "남" : "여"}/{scheduleData.customer.age})
                        </Typography>
                        <Box sx={{width:'auto',height:'18px',fontSize:'14px',maxWidth:'10%',position:'absolute',right:'4px'}}>
                            <InfoRoundedIcon sx={{color:'#bdbdbd',fontSize:'14px'}}/>
                        </Box>
                    </Box>
                    <Typography sx={{width:'100%',height:'18px',fontSize:'12px'}}>
                        {scheduleData.schedule.building} {scheduleData.schedule.floor} {scheduleData.schedule.room}
                    </Typography>
                    <Typography sx={{ width: '100%', height: '18px', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{color: scheduleData.schedule.doctor === null ? '#ff4d4d' : 'black',marginRight: '5px',fontSize:'12px'}}>
                            {scheduleData.schedule.doctor === null ? '의사 미지정' : scheduleData.schedule.doctor}
                        </Typography>
                        <Typography sx={{ marginRight: '5px',fontSize:'12px' }}>/</Typography>
                        <Typography sx={{color: scheduleData.schedule.counselor === null ? '#ff4d4d' : 'black',fontSize:'12px'}}>
                            {scheduleData.schedule.counselor === null ? '실장 미지정' : scheduleData.schedule.counselor}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid container item xs={2.5} direction="column" justifyContent="center" alignItems="center">
                    <Button sx={{ width: '100%', height: '24px', fontSize: '12px', textTransform: 'none', mb: "6px",minWidth:"unset"}}onClick={() => handleCustomerWait(scheduleData.schedule.scheduleId)}>
                        대기
                    </Button>
                    <Button sx={{ width: '100%', height: '24px', fontSize: '12px', textTransform: 'none', minWidth:"unset",backgroundColor: 'lightgray',color: 'black','&:hover': {backgroundColor: '#4f4f4f',color: 'white'}}}
                        onClick={() => {if(window.confirm("고객님의 시술이 완료되고 귀가하셨습니까?")) {handleCustomerReturn(scheduleData.schedule.scheduleId)}}}>
                        귀가
                    </Button>
                </Grid>
            </Grid>
            <Grid container item sx={{width:'100%',height:'18px'}}>
            <Typography sx={{width:'100%',height:'18px',fontSize:'12px',color: scheduleData.service.length===0?'#ff4d4d' :"black" }}>
                        {scheduleData.service.length===0? "시술 플랜 미입력": scheduleData.service.length}
                    </Typography>
            </Grid>

        </Grid>
    )
}
export default VisitingCard