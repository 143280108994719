import axios from "axios";
import { WorkSchedule2 } from "../models/DoctorsModel";

class DoctorController {
  private apiUrl = "/api/v1/doctor";

  async fetchDoctorWeekSchedule(date: Date) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const doctorSchedulesResponse = await axios.post(
        `${this.apiUrl}/doctorSchedule/week`,
        { date: date },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (doctorSchedulesResponse.status === 200) {
        const data = doctorSchedulesResponse.data;
        return data;
      }
    } catch (error: any) {
      console.error("fetch Doctor Schedule failed", error);
      if (error.response.status === 404) {
        alert("의사 스케쥴 불러오기 실패\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("의사 스케쥴 불러오기 실패\n잘못된 접근입니다.");
      }
    }
  }
  async fetchDoctorTodaySchedule(date: Date) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const doctorSchedulesResponse = await axios.post(
        `${this.apiUrl}/doctorSchedule/today`,
        { date: date },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (doctorSchedulesResponse.status === 200) {
        const data = doctorSchedulesResponse.data;
        return data;
      }
    } catch (error: any) {
      console.error("fetch Doctor Schedule failed", error);
      if (error.response.status === 404) {
        alert("의사 스케쥴 불러오기 실패\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("의사 스케쥴 불러오기 실패\n잘못된 접근입니다.");
      }
    }
  }

  async updateDoctorSchedule(data: WorkSchedule2[]) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const doctorSchedulesResponse = await axios.post(`${this.apiUrl}/doctorSchedule/update`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (doctorSchedulesResponse.status === 200) {
        const data = doctorSchedulesResponse.data;
        return data;
      }
    } catch (error: any) {
      console.error("fetch Doctor Schedule failed", error);
      if (error.response.status === 404) {
        alert("의사 스케쥴 불러오기 실패\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("의사 스케쥴 불러오기 실패\n잘못된 접근입니다.");
      }
    }
  }
}
export const doctorController = new DoctorController();
