import axios from "axios";
import { Device } from "../models/DevicesModel";

class DevicesController {
  private apiUrl = "/api/v1";
  async handleFetchDeviceList() {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const fetchResponse = await axios.post(`${this.apiUrl}/device`, {}, { headers: { Authorization: `Bearer ${token}` } });
      if (fetchResponse.status === 200) {
        return fetchResponse.data;
      }
    } catch (error: any) {
      console.error("Login failed", error);
      if (error.response.status === 404) {
        alert("장비 정보를 불러오지 못했습니다.\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("장비 정보를 불러오지 못했습니다.");
      }
    }
  }

  async handleCreateDevice(deviceDto: FormData) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const fetchResponse = await axios.post("/api/v1/device/register", deviceDto, { headers: { Authorization: `Bearer ${token}` } });

      if (fetchResponse.status === 200) {
        return fetchResponse;
      }
    } catch (error: any) {
      console.error("Login failed", error);
      if (error.response.status === 404) {
        alert("장비 정보를 추가하지 못했습니다.\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("장비 정보를 추가하지 못했습니다.");
      }
    }
  }

  async handleUpdateDevice(deviceDto: Device) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const fetchResponse = await axios.post("/api/v1/device/update", deviceDto, { headers: { Authorization: `Bearer ${token}` } });
      if (fetchResponse.status === 200) {
        return fetchResponse;
      }
    } catch (error: any) {
      console.error("Login failed", error);
      if (error.response.status === 404) {
        alert("장비 정보를 추가하지 못했습니다.\n잘못된 접근입니다.");
      } else if (error.response.status === 401) {
        alert("장비 정보를 추가하지 못했습니다.");
      }
    }
  }
}
export const devicesController = new DevicesController();
