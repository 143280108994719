import { Box, FormGroup, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useDevicesLocal } from "../context/DevicesLocalContext";

export interface SearchBarProps {}

const SearchBar: React.FC<SearchBarProps> = () => {
  const { setSearchQuery } = useDevicesLocal();
  const [inputValue, setInputValue] = useState<string>("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleSearchSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchQuery(inputValue);
  };

  return (
    <Box sx={{ width: "100%", mx: "auto" }}>
      <form onSubmit={handleSearchSubmit}>
        <FormGroup>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="장비 검색"
              size="small"
              value={inputValue}
              onChange={handleSearchChange}
              InputProps={{
                sx: { padding: 0, height: "40px" },
              }}
              sx={{ mr: 0.5 }}
            />
            <IconButton
              type="submit"
              aria-label="search"
              sx={{
                width: 40,
                height: 40,
                borderRadius: 1,
                backgroundColor: "primary.main",
                color: "white",

                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              <SearchIcon style={{ fill: "white" }} />
            </IconButton>
          </Box>
        </FormGroup>
      </form>
    </Box>
  );
};

export default SearchBar;
