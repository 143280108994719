import React, { useState, Dispatch, SetStateAction } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Box,
  IconButton,
  Select,
  MenuItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
} from "@mui/material";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Employee, initialEmployee } from "../../models/EmployeeModel";
import { employeeController } from "../../controllers/EmployeeController";
import { formatDate } from "../../util/Function";

interface RegisterEmployeeProp {
  isAddEmployee: boolean;
  setIsAddEmployee: (newState: boolean) => void; // 상태 변경 함수
  setEmployees: Dispatch<SetStateAction<Employee[]>>;
  employees: Employee[]; // 직원 배열
}

const RegisterEmployee: React.FC<RegisterEmployeeProp> = ({
  isAddEmployee,
  setIsAddEmployee,
  setEmployees,
  employees,
}) => {
  const teamList = ["운영", "의사", "기획", "마케팅"]; //팀 리스트
  const [newEmployee, setNewEmployee] = useState<Employee>(initialEmployee);
  const [isIncomplete, setIsIncomplete] = useState<boolean>(false); // 경고창 표시 여부
  //

  const handleChangeEmployee = (field: keyof Employee, value: string | Date) => {
    setNewEmployee({ ...newEmployee, [field]: value });
  };
  const [image, setImage] = useState<File>(); // 업로드된 파일을 저장
  const [previewUrl, setPreviewUrl] = useState<string>(); // 미리보기 URL

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // 첫 번째 선택한 파일
    if (file) {
      setImage(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
  };
  const handleDeleteImage = () => {
    setImage(undefined);
    setPreviewUrl(undefined);
  };

  const handleRegisterNewEmployee = async () => {
    // 필수 입력값 체크
    const isAllFieldsFilled =
      newEmployee.name &&
      newEmployee.loginId &&
      newEmployee.password &&
      newEmployee.team &&
      newEmployee.role &&
      newEmployee.phoneNumber &&
      newEmployee.joiningDate;

    if (!isAllFieldsFilled) {
      setIsIncomplete(true); // 필수값 누락 시 경고창 표시
      return;
    }

    // 등록 API 호출
    const registerResponse = await employeeController.updatePassword(newEmployee, setEmployees);

    if (registerResponse !== null) {
      setIsAddEmployee(false);
    } else {
      setIsRegistering(false);
      setIsAddEmployee(false);
    }
  };

  const handleCancelRegisterNewEmployee = () => {
    setIsAddEmployee(false);
  };

  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container item sx={{ flexShrink: 0, position: "relative", alignItems: "center" }}>
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          신규 직원 등록
        </Typography>
      </Grid>
      <Paper sx={{ flexGrow: 1, height: 0, boxShadow: 3, borderRadius: "8px", display: "flex" }}>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "8px 0px 0px 8px" }} />
        <Grid
          container
          item
          xs={6}
          sx={{ height: "100%", padding: "12px", textAlign: "center", fontSize: "14px", position: "relative" }}
          direction="column"
        >
          <Grid container item xs={3} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                width: "70%",
                height: "70%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                position: "relative",
              }}
            >
              {previewUrl ? (
                <Box
                  sx={{
                    width: "40%",
                    height: "100px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box sx={{ aspectRatio: "1", height: "100px", borderRadius: "50%" }}>
                    <img
                      src={previewUrl}
                      alt="직원 사진"
                      style={{ aspectRatio: "1", height: "100%", borderRadius: "50%", objectFit: "cover" }}
                    />
                  </Box>
                  <input
                    id="image-edit"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-edit">
                    <IconButton component="span" sx={{ padding: "4px", position: "absolute", bottom: 0, right: 24 }}>
                      <EditRoundedIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </label>
                  <IconButton
                    sx={{ padding: "4px", position: "absolute", bottom: 0, right: 0 }}
                    onClick={handleDeleteImage}
                  >
                    <CloseRoundedIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    aspectRatio: "1",
                    height: "100px",
                    borderRadius: "50%",
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="image-upload">
                    <IconButton component="span" sx={{ padding: "10px" }}>
                      <UploadRoundedIcon sx={{ color: "#ccc", fontSize: "30px" }} />
                    </IconButton>
                  </label>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <TextField
              variant="standard"
              label="이름"
              size="small"
              sx={{ width: "70%", margin: "0px" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={newEmployee.name}
              onChange={(e) => handleChangeEmployee("name", e.target.value)}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <TextField
              variant="standard"
              label="아이디"
              size="small"
              sx={{ width: "70%", margin: "0px" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={newEmployee.loginId}
              onChange={(e) => handleChangeEmployee("loginId", e.target.value)}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center" }}>
            <TextField
              variant="standard"
              label="비밀번호"
              size="small"
              sx={{ width: "70%", margin: "0px" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={newEmployee.password}
              onChange={(e) => handleChangeEmployee("password", e.target.value)}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
            <FormControl
              sx={{ width: "35%", display: "flex", flexDirection: "row", alignItems: "center", marginRight: "30px" }}
            >
              <InputLabel id="demo-simple-select-helper-label" sx={{ marginRight: "10px" }}>
                팀
              </InputLabel>
              <Select
                value={newEmployee.team || "팀 선택"}
                label="팀"
                renderValue={() =>
                  newEmployee.team ? <em>{newEmployee.team}</em> : <em style={{ padding: "0px" }}>팀 선택</em>
                }
                onChange={(e) => handleChangeEmployee("team", e.target.value)}
                sx={{
                  height: "40px",
                  flexGrow: 1, // Select가 남은 공간을 차지하도록 설정
                  "& .MuiSelect-select": {
                    padding: "0px",
                    minHeight: "0px",
                    lineHeight: "normal",
                  },
                  "& .MuiInputBase-input": {
                    color: "black", // 선택된 값 텍스트 색상
                  },
                }}
              >
                {teamList.map((value, index) => (
                  <MenuItem key={index} value={value} sx={{ margin: "0px" }}>
                    <ListItemText
                      primary={value}
                      primaryTypographyProps={{ style: { padding: "0px", margin: "0px" } }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="standard"
              label="직급"
              size="small"
              sx={{ width: "30%", margin: "0px" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={newEmployee.role}
              onChange={(e) => handleChangeEmployee("role", e.target.value)}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <TextField
              variant="standard"
              label="전화번호"
              size="small"
              sx={{ width: "70%" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={newEmployee.phoneNumber}
              onChange={(e) => handleChangeEmployee("phoneNumber", e.target.value)}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <TextField
              variant="standard"
              type="date"
              label="생년월일"
              size="small"
              sx={{ width: "70%" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={formatDate(newEmployee.birthDate)}
              onChange={(e) => handleChangeEmployee("birthDate", new Date(e.target.value))}
            ></TextField>
          </Grid>
          <Grid container item xs={1} sx={{ justifyContent: "center", paddingTop: "4px" }}>
            <TextField
              variant="standard"
              type="Date"
              label="입사날짜"
              size="small"
              sx={{ width: "70%" }}
              inputProps={{ style: { padding: "2px 4px", textAlign: "right", fontSize: "16px" } }}
              InputLabelProps={{ shrink: false }}
              value={formatDate(newEmployee.joiningDate)}
              onChange={(e) => handleChangeEmployee("joiningDate", new Date(e.target.value))}
            ></TextField>
          </Grid>
          <Grid
            container
            item
            xs={2}
            sx={{ height: "100%", overflow: "hidden", justifyContent: "center", paddingTop: "24px" }}
          >
            <Button sx={{ marginRight: "8px", height: "30px", padding: "0px" }} onClick={handleRegisterNewEmployee}>
              저장
            </Button>
            <Button
              sx={{ marginLeft: "8px", height: "30px", padding: "0px" }}
              onClick={handleCancelRegisterNewEmployee}
            >
              취소
            </Button>
          </Grid>
          {isRegistering && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                color: "#655dc6",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              등록 중...
            </Box>
          )}
        </Grid>
        <Grid item xs={3} sx={{ background: "#f0f0fb", borderRadius: "0px 8px 8px 0px" }} />
      </Paper>
      <Dialog open={isIncomplete} onClose={() => setIsIncomplete(false)}>
        <DialogTitle>입력 누락</DialogTitle>
        <DialogContent>모든 필수 입력 필드를 작성해 주세요.</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsIncomplete(false)}>확인</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default RegisterEmployee;
