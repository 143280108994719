import { NoticeItem, WorkAttendanceItem } from "../models/HomeModel";
import axios from "axios";
import {formatDate} from "../util/Function"


class NoticeController {
  private apiUrl = "/api/v1/home";
  // 공지사항 함수
  async fetchNotice() {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const fetchNoticeResponse = await axios.post(
        `${this.apiUrl}/notice`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (fetchNoticeResponse.status === 200) {
        return fetchNoticeResponse.data;
      }
    } catch (error: any) {
      alert("공지사항 조회 실패 \n상욱님에게 문의해주세요.");
    }
  }
  async addNotice(
    newNotice: string,
    userUuid: string | undefined,
    setNotices: React.Dispatch<React.SetStateAction<NoticeItem[]>>,
    setNewNotice: React.Dispatch<React.SetStateAction<string>>
  ) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const addNoticeResponse = await axios.post(
        `${this.apiUrl}/notice/add`,
        { noticeText: newNotice, createdByUuid: userUuid },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (addNoticeResponse.status === 200) {
        setNewNotice("");
        setNotices((prevNotices) => [...prevNotices, addNoticeResponse.data]);
      }
    } catch (error: any) {
      alert("공지사항 추가 실패 \n상욱님에게 문의해주세요.");
    }
  }
  async deleteNotice(noticeUuid: string, setNotices: React.Dispatch<React.SetStateAction<NoticeItem[]>>) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const deleteNoticeResponse = await axios.post(
        `${this.apiUrl}/notice/delete`,
        { noticeUuid: noticeUuid },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (deleteNoticeResponse.status === 200) {
        const updatedNotices = await this.fetchNotice();
        if (updatedNotices) {
          setNotices(updatedNotices);
        }
        return deleteNoticeResponse.data;
      }
    } catch (error: any) {
      alert("공지사항 삭제 실패 \n상욱님에게 문의해주세요.");
    }
  }
}
export const noticeController = new NoticeController();

class WorkAttendanceController {
  private apiUrl = "/api/v1/home";
  //출근명부
  async fetchWorkAttendance() {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const fetchWorkAttendanceResponse = await axios.post(
        `${this.apiUrl}/workAttendance`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (fetchWorkAttendanceResponse.status === 200) {
        console.log(fetchWorkAttendanceResponse);
        return fetchWorkAttendanceResponse.data;
      } else if (fetchWorkAttendanceResponse.status === 404) {
      }
    } catch (error: any) {
      alert("출근명부 조회 실패 \n상욱님에게 문의해주세요.");
    }
  }
  async updateWorkAttendance(
    newNotice: string | undefined,
    workAttendanceId: number | undefined,
    setWorkAttendance: React.Dispatch<React.SetStateAction<WorkAttendanceItem | undefined>>
  ) {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const updateWorkAttendanceResponse = await axios.post(
        `${this.apiUrl}/workAttendance/update`,
        { workAttendanceId: workAttendanceId, text: newNotice },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (updateWorkAttendanceResponse.status === 200) {
        setWorkAttendance(updateWorkAttendanceResponse.data);
      } else if (updateWorkAttendanceResponse.status === 204) {
      }
    } catch (error: any) {
      alert("출근명부 저장 실패\n상욱님에게 문의해주세요.");
    }
  }
}
export const workAttendanceController = new WorkAttendanceController();


export class ScheduleController {
  static apiUrl = "/api/v1/schedule"
  static async fetchSchedule (){
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return [];
    }
    try {
      const scheduleResponse = await axios.post(`${this.apiUrl}/fetch`, {date : formatDate(new Date()) },{headers:{Authorization: `Bearer ${token}`}});
        if (scheduleResponse.status === 200) {
          return scheduleResponse.data;
        } else {
          alert("스케쥴을 불러오지 못했습니다. \n상욱님에게 문의해주세요")
          console.error('Failed to fetch schedule info');
        }
    } catch (error) {
        alert("스케쥴을 불러오지 못했습니다. \n상욱님에게 문의해주세요")
        console.error('Error fetching schedule info', error);
    }
  };

  static async handleFileUpload (file: File){
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post(`${this.apiUrl}/uploadExcel`, formData, {headers: {Authorization: `Bearer ${token}`,'Content-Type': 'multipart/form-data',},});
      if (response.status === 200) {
        const jsonResult = response.data;
        return(JSON.stringify(jsonResult, null, 2));
      } else {
        alert("엑셀 파일을 업로드하지 못했습니다. \n상욱님에게 문의해주세요")
        console.log('Error uploading file');
      }
    } catch (error) {
      alert("엑셀 파일을 업로드하지 못했습니다. \n상욱님에게 문의해주세요")
      console.error('Error:', error);
    }
  };
  static async visitCustomer (scheduleId:string,building?:string,floor?:string,room?:string) {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }
    const requestData: { scheduleId: string; building?: string; floor?: string; room?: string } = { 
      scheduleId 
    };
    if (building && floor && room) {
      requestData.building = building;
      requestData.floor = floor;
      requestData.room = room;
    }
    try {
      const visitResponse = await axios.post(`${this.apiUrl}/visit`,requestData , {headers: {Authorization: `Bearer ${token}`}});
      if (visitResponse.status === 200) {
        return visitResponse.data;
      } else {
        alert("빙문 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
        console.log('Error uploading file');
      }
    } catch (error) {
      alert("빙문 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
      console.error('Error:', error);
    }
  }
  static async cancelSchedule (scheduleId:string) {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }
    try {
      const visitResponse = await axios.post(`${this.apiUrl}/cancel`,{scheduleId : scheduleId} , {headers: {Authorization: `Bearer ${token}`}});
      if (visitResponse.status === 200) {
        return visitResponse.data;
      } else {
        alert("취소 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
        console.log('Error uploading file');
      }
    } catch (error) {
      alert("취소 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
      console.error('Error:', error);
    }
  }
  static async customerWait (scheduleId:string) {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }
    try {
      const visitResponse = await axios.post(`${this.apiUrl}/wait`,{scheduleId : scheduleId} , {headers: {Authorization: `Bearer ${token}`}});
      if (visitResponse.status === 200) {
        return visitResponse.data;
      } else {
        alert("대기 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
        console.log('Error uploading file');
      }
    } catch (error) {
      alert("대기 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
      console.error('Error:', error);
    }
  }
  static async customerReturn (scheduleId:string) {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found');
        return;
    }
    try {
      const visitResponse = await axios.post(`${this.apiUrl}/return`,{scheduleId : scheduleId} , {headers: {Authorization: `Bearer ${token}`}});
      if (visitResponse.status === 200) {
        return visitResponse.data;
      } else {
        alert("귀가 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
        console.log('Error uploading file');
      }
    } catch (error) {
      alert("귀가 내용을 업데이트하지 못했습니다. \n상욱님에게 문의해주세요")
      console.error('Error:', error);
    }
  }
}
