import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Box,
  Avatar,
} from "@mui/material";
import { NoticeItem } from "../../models/HomeModel";
import { useAuth } from "../../context/AuthContext";
import { noticeController } from "../../controllers/HomeController";
import { formTime } from "../../util/Function";
import DeleteIcon from "@mui/icons-material/Delete";
import { isWithinTenMinutes } from "../../util/Function";

const Notice: React.FC = () => {
  const { user } = useAuth();
  const [newNotice, setNewNotice] = useState<string>("");
  const [notices, setNotices] = useState<NoticeItem[]>([]);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const fetchedNotices = await noticeController.fetchNotice();
        setNotices(fetchedNotices);
      } catch (error) {
        console.error("Error fetching notices:", error);
      }
    };
    fetchNotices(); // 비동기 함수 호출
  }, []); // 빈 배열로 마운트 시에만 실행

  //1분마다 10분 이내 공지사항 표시
  useEffect(() => {
    const interval = setInterval(() => {
      setNotices((prevNotices) => [...prevNotices]);
    }, 60000); // 매 1분마님다 체크

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: "100%",
        height: "100%",
        padding: "0px 4px 8px 4px",
        flexWrap: "nowrap",
      }}
    >
      <Grid
        container
        item
        sx={{
          flexShrink: 0,
          position: "relative",
          alignItems: "center",
          width: "100%",
          height: "28px",
        }}
      >
        <Typography variant="h2" sx={{ textAlign: "left" }}>
          공지사항
        </Typography>
      </Grid>

      <Grid
        container
        item
        direction="column"
        sx={{
          boxShadow: 3,
          borderRadius: "8px",
          width: "100%",
          height: "calc(100% - 28px)",
          flexGrow: 1,
          padding: "4px 0px 4px 0px",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            height: "calc(100% - 40px)",
          }}
        >
          {notices
            .filter((notice) => !notice.isDeleted)
            .map((notice) => (
              <Box
                key={notice.noticeId}
                sx={{
                  width: "100%",
                  padding: "6px 6px 12px 6px",
                  borderBottom: "1px solid #b0b0b0",
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "13px",
                        marginBottom: "3px",
                      }}
                    >
                      {notice.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontSize: "9px",
                        textAlign: "end",
                        marginLeft: "3px",
                        display: "inline-flex", // 가로 배치
                        alignItems: "center", // 세로 가운데 정렬
                      }}
                    >
                      {formTime(notice.createdAt)}
                      {isWithinTenMinutes(
                        notice.createdAt
                      ) && (
                        <Avatar
                          sx={{
                            bgcolor: "#f5e1e1",
                            width: 10,
                            height: 10,
                            color: "red",
                            fontSize: "7px",
                            marginLeft: "5px", // 요소 간 여백 추가
                          }}
                        >
                          N
                        </Avatar>
                      )}
                    </Typography>
                  </Box>
                  <Grid sx={{ textAlign: "right" }}>
                    <DeleteIcon
                      sx={{
                        fontSize: "16px",
                        color: "#b0b0b0",
                        alignItems: "center",
                        cursor: "pointer",
                        "&:hover": { color: "#ff4d4d" },
                      }}
                      onClick={() => {
                        if (window.confirm("정말로 삭제하시겠습니까?")) {
                          noticeController.deleteNotice(
                            notice.noticeUuid,
                            setNotices
                          );
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Typography sx={{ fontSize: "12px" }}>
                  {notice.noticeText}
                </Typography>
              </Box>
            ))}
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            height: "40px",
          }}
        >
          <TextField
            size="small"
            placeholder="새 공지사항 입력"
            multiline
            maxRows={2}
            value={newNotice}
            onChange={(e) => setNewNotice(e.target.value)}
            sx={{
              height: "30px",

              "& .MuiInputBase-root": {
                fontSize: "10px",
                marginRight: "5px",
                padding: "4px",
                height: "30px", // Make sure the root takes full height
                overflow: "hidden", //
              },
              "& .MuiInputBase-inputMultiline": {
                height: "30px",
                overflow: "auto",
                padding: "1px",
              },
              flex: 5,
            }}
          />

          <Button
            onClick={() =>
              noticeController.addNotice(
                newNotice,
                user?.userUuid,
                setNotices,
                setNewNotice
              )
            }
            sx={{
              width: "100%",
              fontSize: "12px",
              textTransform: "none",
              minWidth: "unset",
              flex: 1,
            }}
          >
            추가
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notice;
