import React, { useState } from "react";
import BasicStructure from "./BasicStructure";
import {
  AppBar,
  Box,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

const TabPanel: React.FC<{ children?: React.ReactNode; index: number; value: number }> = ({ children, index, value }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};
const TabContainerComponent: React.FC<{ value: number }> = ({ value }) => {
  const data = [
    { name: "아이템 1", description: "설명 1" },
    { name: "아이템 2", description: "설명 2" },
  ];
  return (
    <TabPanel value={value} index={value}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>예시1</TableCell>
              <TableCell>예시2</TableCell>
              <TableCell>예시3</TableCell>
              <TableCell>예시4</TableCell>
              <TableCell>예시5</TableCell>
              <TableCell>예시6</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TabPanel>
  );
};

const SurgeryList: React.FC = () => {
  const [value, setValue] = useState(0);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BasicStructure>
      <Box>
        <AppBar position="static" sx={{ backgroundColor: "primary.contrastText", boxShadow: 1 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="프로모션" />
            <Tab label="판매중 상품" />
            <Tab label="판매종료 상품" />
          </Tabs>
        </AppBar>
        <Box>
          <TabContainerComponent value={value} />
        </Box>
      </Box>
    </BasicStructure>
  );
};

export default SurgeryList;
